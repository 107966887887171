import React from 'react';
import App from './app/App';
import {render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './app/ScrollToTop';

render(
    <BrowserRouter basename='/'>
      <ScrollToTop>
        <App/>
      </ScrollToTop>
    </BrowserRouter>,
  document.getElementById('root')
);
