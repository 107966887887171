import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import CheckBox from "../../../../components/CheckBox";
import ImportButtons from "./ImportButtons";
import ImportErrorList from "./ImportErrorList";
import ImportFieldLists from "./ImportFieldLists";
import { AccountModel, CCModel, States } from "./ImportModels";
import { parseAccountData, parseCCData } from "./ImportParser";
import { validateByType } from "./ImportValidator";
import axios from "axios";
import LoadingIcon from "../../../../components/LoadingIcon";

const ImportModalContent = (props) => {
  const [importData, setImportData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [state, setState] = useState([]);
  const [shouldRemoveAllCC, setShouldRemoveAllCC] = useState(false);
  const [shouldRemoveSameCC, setShouldRemoveSameCC] = useState(false);
  const [hasAccountEmailSelected, setHasAccountEmailSelected] = useState(false);
  const [hasCCEmailSelected, setHasCCEmailSelected] = useState(false);

  useEffect(() => {
    if (!!importData && importData.length > 0) {
      setColumns(
        importData[0].map((column, index) => ({
          indexColumn: index,
          columnName: column,
          accountField: "",
          ccField: "",
          error: "",
        }))
      );
      setState(States.fileUploaded);
    }
  }, [importData]);

  const handleFileUpload = (content) => {
    const data = content.reduce((acc, current) => {
      if (current.length > 1) {
        acc.push(current);
      }
      return acc;
    }, []);

    console.log("data", data);
    setImportData(data);
  };

  useEffect(() => {
    const hasColumnWithError = columns.find((column) => !!column.error);
    const hasEmailSelectedAccount = columns.find(
      (column) => column.accountField === "email"
    );
    const hasEmailSelectedCC = columns.find(
      (column) => column.ccField === "accountId"
    );
    setHasAccountEmailSelected(hasEmailSelectedAccount);
    setHasCCEmailSelected(hasEmailSelectedCC);

    if (hasColumnWithError) {
      setState(States.error);
    } else if (hasEmailSelectedAccount || hasEmailSelectedCC) {
      setState(States.canImport);
    } else if (columns.length > 0) {
      setState(States.fileUploaded);
    } else {
      setState(States.initial);
    }
  }, [columns]);

  const handleAccountFieldSelected = (column, field) => {
    const tmpColumns = [...columns];
    const columnFound = tmpColumns.find(
      (c) => c.columnName === column.columnName
    );
    columnFound.accountField = field;

    validateAccountColumn(columnFound);
    setColumns(tmpColumns);
  };

  const handleCCFieldSelected = (column, field) => {
    const tmpColumns = [...columns];
    const columnFound = tmpColumns.find(
      (c) => c.columnName === column.columnName
    );
    columnFound.ccField = field;

    validateCCColumn(columnFound);
    setColumns(tmpColumns);
  };

  const validateAccountColumn = (columnFound) => {
    const modelFound = AccountModel.find(
      (model) => model.value === columnFound.accountField
    );
    columnFound.error = validateByType(importData, columnFound, modelFound);
  };

  const validateCCColumn = (columnFound) => {
    const modelFound = CCModel.find(
      (model) => model.value === columnFound.ccField
    );
    columnFound.error = validateByType(importData, columnFound, modelFound);
  };

  const handleImportAccount = async () => {
    setState(States.loading);
    const parsed = parseAccountData(importData, columns);
    try {
      const result = await axios.post("/api/accounts/import", {
        accounts: parsed,
      });
      if (!!result.error) {
        alert(result.error);
      }
    } catch (error) {
      console.log("error", error);
      alert(error.message);
    }
    setState(States.canImport);
  };

  const handleImportCreditCard = async () => {
    setState(States.loading);
    const parsed = parseCCData(importData, columns);
    try {
      const result = await axios.post("/api/creditCards/import", {
        creditCards: parsed,
        shouldRemoveAllCC,
        shouldRemoveSameCC,
      });
      if (!!result.error) {
        alert(result.error);
      }
    } catch (error) {
      console.log("error", error);
      alert(error.message);
    }
    setState(States.canImport);
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <h3>Upload File</h3>
        <CSVReader onFileLoaded={handleFileUpload} />
        {state !== States.initial && (
          <div style={{ marginTop: "10px" }}>
            <h3>Settings</h3>
            <div>
              <CheckBox
                onChange={setShouldRemoveAllCC}
                label="Remove all credit cards associated with that email"
                value={shouldRemoveAllCC}
              />
              <CheckBox
                onChange={setShouldRemoveSameCC}
                label="Remove other credit cards of same type"
                value={shouldRemoveSameCC}
              />
            </div>
          </div>
        )}
        {state !== States.initial && (
          <ImportButtons
            style={{ marginTop: "10px" }}
            canImportAccount={hasAccountEmailSelected}
            canImportCC={hasCCEmailSelected}
            onImportAccount={handleImportAccount}
            onImportCreditCard={handleImportCreditCard}
          />
        )}
        {state === States.error && <ImportErrorList columns={columns} />}
        {state === States.loading && (
          <div style={{ marginLeft: "20px", marginTop: "20px" }}>
            <LoadingIcon />
          </div>
        )}
      </div>
      {state !== States.initial && (
        <ImportFieldLists
          columns={columns}
          onAccountFieldSelected={handleAccountFieldSelected}
          onCCFieldSelected={handleCCFieldSelected}
        />
      )}
    </div>
  );
};

export default ImportModalContent;
