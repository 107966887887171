import React, {Component} from 'react';

class MainWrapper extends Component {
  render() {
    return (
      <div className="theme-light">
        <div className='wrapper'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default MainWrapper;