import React, { Component } from "react";
import Select from "react-select";
import UserRoles from "../../../components/UserRoles";
import InputEnterAction from "../../../components/InputEnterAction";
import ButtonToCheck from "../../../components/ButtonToCheck";
import Axios from "axios";

class UserCreator extends Component {
  constructor() {
    super();
    this.state = { email: "", role: "" };
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangeRole(event) {
    this.setState({ role: event });
  }

  handleEnter() {
    if (this.state.email && this.state.role) {
      this.sendUser({email:this.state.email, role: this.state.role.value});
    }
  }

  async sendUser(user) {
    Axios.post("/api/users/", user).then(res => {
      this.props.onUserCreated();
    });
  }

  render() {
    const options = Object.keys(UserRoles).map(role => {
      return { value: UserRoles[role], label: UserRoles[role] };
    });
    const style = { display: "inline", width: "225px" };

    return (
      <div>
        <fieldset style={style}>
          <legend>Email</legend>
          <InputEnterAction
            value={this.state.email}
            onChange={this.handleChangeEmail.bind(this)}
            onEnter={this.handleEnter.bind(this)}
          />
        </fieldset>
        <fieldset style={style}>
          <legend>Role</legend>
          <Select
            options={options}
            value={this.state.role}
            onChange={this.handleChangeRole.bind(this)}
          />
        </fieldset>
          <ButtonToCheck onClick={this.handleEnter.bind(this)} text="Create" />
      </div>
    );
  }
}
export default UserCreator;
