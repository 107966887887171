import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Layout from "../containers/_layout";
import MainWrapper from "./MainWrapper";

import Account from "../containers/account/index";
import Accounts from "../containers/accounts";
import AccountLists from "../containers/accountLists";
import Accounting from "../containers/accounting";
import Coachella from "../containers/coachella";
import Distributick from "../containers/distributick";
import FanCodes from "../containers/fanCodes";
import Limits from "../containers/limit";
import LoginPage from "../containers/login";
import ModemPage from "../containers/modem";
import SmsPage from "../containers/sms";
import Users from "../containers/users";

const Router = (props) => {
  return (
    <MainWrapper>
      <main>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route
              exact
              path="/logout"
              render={() => {
                window.location.href = `${process.env.REACT_APP_API_URL}/auth/logout`;
              }}
            />
            <Route
              path="/"
              render={() => {
                return props.user ? wrappedRoutes(props.user) : <LoginPage />;
              }}
            />
          </Switch>
        </BrowserRouter>
      </main>
    </MainWrapper>
  );
};

const wrappedRoutes = (user) => (
  <div>
    <Layout user={user} />
    <div className="container__wrap">
      <Route exact path="/" render={() => <Redirect to="/pages/account" />} />
      <Route
        path="/pages"
        component={(props) => <Pages {...props} user={user} />}
      />
    </div>
  </div>
);

const Pages = (pageProps) => (
  <Switch>
    <Route path="/pages/account" component={Account} />
    <Route path="/pages/accounts" component={Accounts} />
    <Route
      path="/pages/accountLists"
      component={(props) => <AccountLists {...pageProps} {...props} />}
    />
    <Route path="/pages/limits" component={Limits} />
    <Route path="/pages/fanCodes" component={FanCodes} />
    <Route path="/pages/distributick" component={Distributick} />
    <Route path="/pages/coachella" component={Coachella} />
    <Route path="/pages/sms" component={SmsPage} />
    <Route path="/pages/modems" component={ModemPage} />
    <Route path="/pages/users" component={Users} />
    <Route path="/pages/accounting" component={Accounting} />
  </Switch>
);

export default Router;
