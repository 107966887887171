import { CardType } from "./ImportModels";

const columnsWithFieldSelected = (columns, field) => {
  return columns.reduce((acc, current) => {
    if (!!current[field] && !current.error) {
      acc.push(current);
    }
    return acc;
  }, []);
};

const columnsWithAccountFieldSelected = (columns) => {
  return columnsWithFieldSelected(columns, "accountField");
};
const columnsWithCCFieldSelected = (columns) => {
  return columnsWithFieldSelected(columns, "ccField");
};

export const parseAccountData = (data, columns) => {
  const columnsWithSelection = columnsWithAccountFieldSelected(columns);
  const [header, ...content] = data;
  return content.map((row) => {
    let account = {};
    for (
      let indexField = 0;
      indexField < columnsWithSelection.length;
      indexField++
    ) {
      const columnWithValue = columnsWithSelection[indexField];
      account[columnWithValue.accountField] = row[columnWithValue.indexColumn];
    }
    return account;
  });
};

export const parseCCData = (data, columns) => {
  const columnsWithSelection = columnsWithCCFieldSelected(columns);
  const [header, ...content] = data;
  return content.map((row) => {
    let creditCard = {};
    for (
      let indexField = 0;
      indexField < columnsWithSelection.length;
      indexField++
    ) {
      const columnWithValue = columnsWithSelection[indexField];
      if (columnWithValue.ccField === "cardType") {
        creditCard[columnWithValue.ccField] =
          CardType[row[columnWithValue.indexColumn].toLowerCase()].value;
      } else {
        creditCard[columnWithValue.ccField] = row[columnWithValue.indexColumn];
      }
    }
    return creditCard;
  });
};
