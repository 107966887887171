import React from "react";
import CreditCardTable from "./CreditCardTable";
import AccountListsList from "./AccountListsList";
import AccountDetails from "./AccountDetails";
import AccountListAdd from "./AccountListAdd";
import DSInfo from "./DSInfo";

export default function (props) {
  return props.account ? (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}
      >
        <div style={{ width: "50%" }}>
          <h3>Details</h3>
          <AccountDetails
            account={props.account}
            oneTicketStatus={props.oneTicketStatus}
            onOneTicketUpdated={props.onOneTicketUpdated}
            onUpdateAccount={props.onUpdateAccount}
            onAccountUpdated={props.onAccountUpdated}
          />
        </div>
        <div>
          <h3>Lists</h3>
          <AccountListAdd
            accountLists={props.accountLists}
            onAddToList={props.onAddToList}
            currentList={props.currentList}
            onSetCurrentList={props.onSetCurrentList}
          />
          <AccountListsList
            lists={props.account.account_list_accounts}
            email={props.account.email}
            onRemoveFromList={props.onRemoveFromList}
          />
        </div>
        <div>
          <DSInfo
            info={props.dsInfo}
            lists={props.account.account_list_accounts}
            account={props.account}
            onSentToDS={props.onSentToDS}
          />
        </div>
      </div>
      <h3>Credit cards</h3>
      <CreditCardTable
        creditCards={props.account.credit_cards}
        onRemove={(ccId) => props.onRemoveCreditCard(ccId)}
        onCreate={() => props.onCreateCreditCard()}
        onChange={(cc) => props.onChangeCreditCard(cc)}
        onChangeCardType={(target, cc) =>
          props.onChangeCreditCardType(target, cc)
        }
      />
    </>
  ) : (
    <span>Nothing found</span>
  );
}
