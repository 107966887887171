import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import AccountTable from "./components/AccountTable";
import CreateAccount from "./components/CreateAccount";
import AutoCompleteField from "../../components/AutoCompleteField";
import ImportModal from "./components/Import/ImportModal";

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatingAccount: false,
      updatingPassword: null,
      accounts: [],
      accountLists: [],
      currentList: { name: "" },
      filterCurrentList: "",
      oneTicketStatuses: [],
      filters: { cardType: "", nameOnCard: "", last4: "", cvv: "", exp: "" },
    };
    this.setCurrentList = this.setCurrentList.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
    this.handleAccountUpdated = this.handleAccountUpdated.bind(this);
    this.handleCreateCreditCard = this.handleCreateCreditCard.bind(this);
    this.handleChangeCreditCard = this.handleChangeCreditCard.bind(this);
    this.handleRemoveCreditCard = this.handleRemoveCreditCard.bind(this);
    this.handleCardTypeChanged = this.handleCardTypeChanged.bind(this);
    this.handleOneTicketUpdated = this.handleOneTicketUpdated.bind(this);
    this.handleUpdateOneTicket = this.handleUpdateOneTicket.bind(this);
  }

  componentDidMount() {
    this.fetchDataOfTable();
  }

  async fetchDataOfTable() {
    this.setState({ loading: true });
    this.fetchAccountLists();
    this.fetchOneTicketStatus();
    const [accounts, purchases] = await Promise.all([
      this.fetchAccounts(),
      this.fetchPurchases(),
    ]);
    this.mergeAccountsWithPurchases(accounts || [], purchases || []);
    this.setState({ accounts, loading: false });
  }

  async fetchAccounts() {
    let URL = "/api/accounts";
    return await this.callApi(URL);
  }

  async fetchPurchases() {
    let URL = "/api/purchases";
    return await this.callApi(URL);
  }

  async fetchAccountLists() {
    let URL = "/api/accountLists";
    const accountLists = await this.callApi(URL);
    this.setState({ accountLists });
  }

  async fetchOneTicketStatus() {
    const oneTicketStatuses = await this.callApi(`/api/oneticket/error`);
    this.setState({ oneTicketStatuses });
  }

  async mergeAccountsWithPurchases(accounts, purchases) {
    accounts.forEach((account) => {
      let purchase = purchases.find((p) => p.email === account.email);
      if (!purchase || !purchase.lastYear) {
        purchase = { lastYear: 0, thisYear: 0, lastWeek: 0 };
      }
      account.lastYear = parseInt(purchase.lastYear);
      account.thisYear = parseInt(purchase.thisYear);
      account.lastWeek = parseInt(purchase.lastWeek);
    });
  }

  setCurrentList(currentList) {
    if (currentList.id) {
      this.setState({ currentList });
    }
  }

  async addToList(row) {
    if (!this.state.currentList.id) {
      alert('Please select a list in the "Current List" field');
    } else {
      const accounts = [...this.state.accounts];
      const account = accounts.find((val) => val.email === row.email);
      const index = account.account_list_accounts.push({
        accountId: row.email,
        accountListId: this.state.currentList.id,
        account_lists: [
          {
            id: this.state.currentList.id,
            name: this.state.currentList.name,
          },
        ],
      });
      account.account_list_accounts.splice(index, 1);
      this.setState({ accounts });
      this.associate(row);
    }
  }

  async associate(row) {
    axios.post("/api/accountLists/associate", {
      accountListId: this.state.currentList.id,
      accountId: row.email,
    });
  }

  removeFromList(email, listId) {
    const accounts = [...this.state.accounts];
    const account = accounts.find((val) => val.email === email);
    const index = account.account_list_accounts.findIndex(
      (val) => val.id === listId
    );
    account.account_list_accounts.splice(index, 1);
    this.setState({ accounts });
  }

  handleUpdateAccount() {
    this.setState({ accounts: this.state.accounts, updatingAccount: true });
  }

  handleAccountUpdated(account) {
    if (account) {
      const accounts = this.state.accounts;
      const found = accounts.find((acc) => acc.email === account.email);
      found.oneTicketSynced = account.oneTicketSynced;
      this.setState({ accounts, updatingAccount: false });
    }
    this.setState({ updatingAccount: false });
  }

  async handleOneTicketUpdated(email, isSuccess) {
    const accounts = this.state.accounts;
    const accountEdited = accounts.find((account) => email === account.email);
    accountEdited.oneTicketSynced = isSuccess;
    this.setState({
      accounts,
      updatingAccount: false,
      updatingOneTicket: null,
    });
  }

  handleUpdateOneTicket(email) {
    this.setState({ updatingOneTicket: email });
  }

  handleCreateAccount(email) {
    const accounts = this.state.accounts;
    accounts.push({
      email,
      account_list_accounts: [],
      credit_cards: [],
      createdAt: `${moment.tz("UTC").format("YYYY-MM-DDTHH:mm:ss.000")}Z`,
    });
    this.setState({ accounts });
  }

  async callApi(url) {
    try {
      const response = await fetch(url);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const data = await response.json();
        return data;
      }
    } catch (err) {
      console.error(err);
    }
    return undefined;
  }

  async handleCreateCreditCard(account) {
    const accounts = this.state.accounts;
    const accountState = accounts.find((a) => a.email === account.email);
    const creditCard = {
      accountId: account.email,
      nameOnCard: "",
      cardType: 1,
      last4: "",
      exp: "",
      cvv: "",
    };
    const id = await this.saveCreditCard(creditCard);
    creditCard.id = id;
    accountState.credit_cards.push(creditCard);
    this.setState({ accounts });
  }

  handleChangeCreditCard(account, cc) {
    this.saveCreditCard(cc);
  }

  filterAccounts() {
    let accounts = this.state.accounts;
    const { cardType, nameOnCard, last4, cvv, exp } = this.state.filters;
    console.log("cardType", cardType);
    const hasFilter = !!cardType || !!nameOnCard || !!last4 || !!cvv || !!exp;
    accounts = accounts.filter((account) => {
      return (
        !hasFilter ||
        account.credit_cards.some((cc) => {
          if (cardType && cc.cardType !== parseInt(cardType, 10)) {
            return false;
          }
          if (
            nameOnCard &&
            (!cc.nameOnCard ||
              !cc.nameOnCard.toLowerCase().includes(nameOnCard.toLowerCase()))
          ) {
            return false;
          }
          if (last4 && (!cc.last4 || !cc.last4.includes(last4))) {
            return false;
          }
          if (cvv && (!cc.cvv || !cc.cvv.includes(cvv))) {
            return false;
          }
          if (exp && (!cc.exp || !cc.exp.includes(exp))) {
            return false;
          }
          return true;
        })
      );
    });
    this.setState({ filteredAccounts: accounts });
  }

  changeCVV(e) {
    const filters = this.state.filters;
    filters.cvv = e.target.value;
    this.setState({ filters }, () => this.filterAccounts());
  }

  changeExpiration(e) {
    const filters = this.state.filters;
    filters.exp = e.target.value;
    this.setState({ filters }, () => this.filterAccounts());
  }

  changeNameOnCard(e) {
    const filters = this.state.filters;
    filters.nameOnCard = e.target.value;
    this.setState({ filters }, () => this.filterAccounts());
  }

  changeLast4(e) {
    const filters = this.state.filters;
    filters.last4 = e.target.value;
    this.setState({ filters }, () => this.filterAccounts());
  }

  changeCardType(e) {
    const filters = this.state.filters;
    console.log("e", e);
    filters.cardType = e.target.value;
    this.setState({ filters }, () => this.filterAccounts());
  }

  async handleRemoveCreditCard(account, ccId) {
    if (window.confirm("Do you really want to delete that credit card?")) {
      const accounts = this.state.accounts;
      const accountState = accounts.find((a) => a.email === account.email);
      accountState.credit_cards = accountState.credit_cards.filter(
        (item) => item.id !== ccId
      );
      this.setState({ accounts });
      await axios.delete(`/api/creditCards/${ccId}`);
    }
  }

  async handleCardTypeChanged(account, target, original) {
    const accounts = this.state.accounts;
    const accountState = accounts.find((a) => a.email === account.email);
    const creditCard = accountState.credit_cards.find(
      (item) => item.id === original.id
    );
    creditCard.cardType = parseInt(target.value, 10);
    this.saveCreditCard(creditCard);
    this.setState({ accounts });
  }

  async saveCreditCard(creditCard) {
    const response = await axios.post("/api/creditcards/", creditCard);
    if (!creditCard.id) {
      return response.data.id;
    }
    return creditCard.id;
  }

  render() {
    const {
      loading,
      updatingAccount,
      updatingOneTicket,
      filteredAccounts,
      oneTicketStatuses,
      accounts,
      currentList,
      filterCurrentList,
      accountLists,
      filters,
    } = this.state;
    const { cardType, nameOnCard, last4, cvv, exp } = this.state.filters;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <fieldset>
            <legend>Account List</legend>
            <AutoCompleteField
              key={"autocomplete"}
              placeholder="Current List"
              onSelectionChange={this.setCurrentList}
              suggestions={this.state.accountLists}
              value={filterCurrentList.name}
            />
          </fieldset>
          <fieldset>
            <legend>Create Account</legend>
            <CreateAccount onCreate={this.handleCreateAccount} />
          </fieldset>
          <fieldset>
            <legend>Credit Card Filters</legend>
            <div className="search-field">
              <select
                className={"input"}
                value={cardType}
                placeholder="Card Type"
                onChange={this.changeCardType.bind(this)}
              >
                <option value="">Card Types All</option>
                <option value="1">Amex</option>
                <option value="2">ComData</option>
                <option value="3">MC</option>
              </select>
            </div>
            <div className="search-field">
              <input
                className={"input"}
                value={nameOnCard}
                placeholder="Name on card"
                onChange={this.changeNameOnCard.bind(this)}
              />
            </div>
            <div className="search-field">
              <input
                className={"input"}
                value={last4}
                placeholder="Last 4"
                onChange={this.changeLast4.bind(this)}
              />
            </div>
            <div className="search-field">
              <input
                className={"input"}
                value={cvv}
                placeholder="CVV"
                onChange={this.changeCVV.bind(this)}
              />
            </div>
            <div className="search-field">
              <input
                className={"input"}
                placeholder="Expiration"
                value={exp}
                onChange={this.changeExpiration.bind(this)}
              />
            </div>
          </fieldset>
          <ImportModal style={{ alignSelf: "end", marginBottom: "4px" }} />
        </div>
        <AccountTable
          loading={loading}
          updatingAccount={updatingAccount}
          updatingOneTicket={updatingOneTicket}
          accounts={filteredAccounts || accounts}
          currentList={currentList}
          accountLists={accountLists}
          externalFilter={filters}
          oneTicketStatuses={oneTicketStatuses}
          onCreateCreditCard={this.handleCreateCreditCard}
          onChangeCreditCard={this.handleChangeCreditCard}
          onChangeCreditCardType={this.handleCardTypeChanged}
          onRemoveCreditCard={this.handleRemoveCreditCard}
          onAddToList={(row) => this.addToList(row)}
          onRemoveFromList={(email, listId) =>
            this.removeFromList(email, listId)
          }
          onUpdateAccount={this.handleUpdateAccount}
          onOneTicketUpdated={this.handleOneTicketUpdated}
          onUpdateOneTicket={this.handleUpdateOneTicket}
          onAccountUpdated={this.handleAccountUpdated}
        />
      </div>
    );
  }
}

export default Accounts;
