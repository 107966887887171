export const AccountModel = [
  { value: "", text: "", type: "", maxLength: 0 },
  { value: "email", text: "Email", type: "email", maxLength: 511 },
  { value: "password", text: "Password", type: "string", maxLength: 254 },
  { value: "index", text: "Index", type: "int" },
  { value: "name", text: "Name", type: "string", maxLength: 254 },
  { value: "proxy", text: "Proxy", type: "string", maxLength: 20 },
  { value: "address", text: "Address", type: "string", maxLength: 511 },
  { value: "city", text: "City", type: "string", maxLength: 254 },
  { value: "postalCode", text: "Postal Code", type: "string", maxLength: 31 },
  { value: "metro", text: "Metro", type: "string", maxLength: 254 },
  { value: "state", text: "State", type: "string", maxLength: 254 },
  {
    value: "fanVerifStates",
    text: "Fan Verif States",
    type: "string",
    maxLength: 254,
  },
  { value: "country", text: "Country", type: "string", maxLength: 45 },
  { value: "phone", text: "Phone", type: "phone", maxLength: 15 },
  { value: "platform", text: "Platform", type: "string", maxLength: 254 },
  { value: "comment", text: "Comment", type: "string", maxLength: 511 },
  { value: "accountListId", text: "Account List ID", type: "int" },
];

export const CCModel = [
  { value: "", text: "", type: "", maxLength: 0 },
  { value: "last4", text: "Last 4", type: "string", maxLength: 5 },
  { value: "nameOnCard", text: "Name On Card", type: "string", maxLength: 254 },
  { value: "exp", text: "Expiration", type: "string", maxLength: 7 },
  { value: "cardType", text: "Card Type", type: "cardType" },
  { value: "cvv", text: "CVV", type: "string", maxLength: 5 },
  { value: "accountId", text: "Email", type: "email", maxLength: 254 },
];

export const CardType = {
  amex: { text: "amex", value: 1 },
  comdata: { text: "comdata", value: 2 },
  mc: { text: "mc", value: 3 },
  hsbc: { text: "hsbc", value: 4 },
};

export const States = {
  canImport: "canImport",
  fileUploaded: "fileUploaded",
  error: "error",
  initial: "initial",
  loading: "loading",
};
