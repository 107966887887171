import React, { Component } from "react";
import querystring from "query-string";
import SearchBar from "./SearchBar";
import AccountList from "./AccountList";

class AccountListContainer extends Component {
  constructor() {
    super();
    this.state = { loading: false, provinceFilter: {} };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async handleSearch(eventName, venue, eventDate) {
    this.setState({ loading: true });
    this.syncPurchases();
    await this.getPurchases(eventName, venue, eventDate);
    this.setState({ loading: false });
  }

  async getPurchases(event, venue, date) {
    let URL = "/api/reports/getPurchasesByUserForEvent?";
    URL += querystring.stringify({ eventDate: date, venue, event });
    try {
      const response = await fetch(URL);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const reportData = await response.json();
        if (this.mounted) {
          this.setState({
            events: reportData.events,
            accounts: reportData.accounts,
            loading: false
          });
        }
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    if (this.mounted) {
      this.setState({ loading: false });
    }

    return false;
  }

  syncPurchases() {
    if (!this.state.isSynchingPurchases) {
      let URL = "/api/purchases/syncAllPurchases";
      try {
        this.setState({ isSynchingPurchases: true });
        fetch(URL).then(() => {
          if (this.mounted) {
            this.setState({ isSynchingPurchases: false });
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  render() {
    return (
      <div>
        <SearchBar onSearch={this.handleSearch.bind(this)} />
        <AccountList
          events={this.state.events}
          accounts={this.state.accounts}
          loading={this.state.loading}
          provinceFilter={this.state.provinceFilter}
        />
      </div>
    );
  }
}

export default AccountListContainer;
