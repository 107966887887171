import React from "react";
export default function PromptEditable(props) {
  return (
    <div
      style={{ backgroundColor: "#fafafa", width: "100%", height: "24px" }}
      onClick={(e) => {
        const value = prompt(
          `Please input the new ${props.cellInfo.column.Header}`,
          props.cellInfo.value === null ? "" : props.cellInfo.value
        );
        if (value !== null) {
          props.item[props.cellInfo.column.id] = value;
          props.itemChanged(props.item, props.cellInfo.column.id);
        }
      }}
      dangerouslySetInnerHTML={{
        __html: props.item[props.cellInfo.column.id],
      }}
    />
  );
}
