import axios from "axios";
import React from "react";

export default function (props) {
  const isDSAccount = () => {
    return (
      props.lists &&
      props.lists.find((list) => [37, 38].includes(list.accountListId))
    );
  };

  const inexistantMessage = (field) => {
    alert(`You must specify ${field} in Karlos data`);
  };

  const sendToDS = async () => {
    if (!props.account.index) {
      inexistantMessage("an index");
    } else if (!props.account.password) {
      inexistantMessage("the password");
    } else if (!props.account.email) {
      inexistantMessage("the email");
    } else {
      await axios.post(
        `https://drop.plessinc.com/accounts/create/${props.account.index}`,
        { email: props.account.email, password: props.account.password }
      );
      props.onSentToDS();
    }
  };

  return (
    <>
      <h3>DS Infos</h3>
      <div>
        {props.info ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <ul className="flex-outer">
              <li>
                <a href="https://ds.plessinc.com/accounts" target="_blank">
                  ds.plessinc.com
                </a>
              </li>
              <li>
                <span>ID: {props.info.id}</span>
              </li>
              <li>
                <span>Email: {props.info.email}</span>
              </li>
              <li>
                <span>Password: {props.info.password}</span>
              </li>
              <li>
                <span>Firstname: {props.info.firstname}</span>
              </li>
              <li>
                <span>Last Name: {props.info.lastname}</span>
              </li>
              <li>
                <span>Phone: {props.info.phone}</span>
              </li>
              <li>
                <span>Zipcode: {props.info.zipcode}</span>
              </li>
              <li>
                <span>Cc Cvv: {props.info.cc_cvv}</span>
              </li>
              <li>
                <span>Cc_Last4: {props.info.cc_last4}</span>
              </li>
              <li>
                <span>Cc Type: {props.info.cc_type}</span>
              </li>
              <li>
                <span>Amex Safekey: {props.info.amex_safekey}</span>
              </li>
              <li>
                <span>Is Busy: {props.info.is_busy}</span>
              </li>
              <li>
                <span>Busy Since: {props.info.busy_since}</span>
              </li>
              <li>
                <span>Last_ping: {props.info.last_ping}</span>
              </li>
              <li>
                <span>Proxy_url: {props.info.proxy_url}</span>
              </li>
              <li>
                <span>Is Cad: {props.info.is_cad}</span>
              </li>
              <li>
                <span>Is Ds: {props.info.is_ds}</span>
              </li>
              <li>
                <span>Is Hidden: {props.info.is_hidden}</span>
              </li>
              <li>
                <span>Is Locked: {props.info.is_locked}</span>
              </li>
              <li>
                <span>Is Verified: {props.info.is_verified}</span>
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <div>
              <span>
                Not In{" "}
                <a href="https://ds.plessinc.com/accounts" target="_blank">
                  ds.plessinc.com
                </a>
              </span>
            </div>
            {isDSAccount() && (
              <div>
                <button
                  className="btn btn-outline-primary btn-xsm"
                  onClick={sendToDS}
                >
                  Create in ds.plessinc.com
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
