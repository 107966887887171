const {
  match,
  hasSameLast4,
  findColumnIndex,
  filterByCost,
} = require("./transactionMatcher");

const formatRawToObject = (amexData) => {
  const headerRowIndex = amexData.findIndex((row) => row.includes("Full Name"));
  const headerRow = amexData[headerRowIndex];
  const rocIdIndex = findColumnIndex(headerRow, "ROC ID");
  const creditCardIndex = findColumnIndex(headerRow, "Cardmember Acct. No.");
  const transactionDescription3 = findColumnIndex(
    headerRow,
    "Transaction Description 3"
  );
  const transactionDescriptionIndex = findColumnIndex(
    headerRow,
    "Transaction Description"
  );
  const costTotalIndex = findColumnIndex(
    headerRow,
    "Submitted Currency Amount"
  );
  const supplierIndex = findColumnIndex(headerRow, "Supplier Name");
  const transactionDateIndex = findColumnIndex(headerRow, "Charge Date");

  return amexData.slice(headerRowIndex + 1, amexData.length - 1).map((t) => {
    if (t.length > rocIdIndex) {
      let creditCard = t[transactionDescription3].includes("PXY#")
        ? t[transactionDescription3]
        : t[creditCardIndex];
      return {
        confirmationNumber: t[rocIdIndex],
        creditCard,
        totalCost: parseFloat(
          t[costTotalIndex].replace(/,/g, "").replace("(", "-")
        ).toFixed(2),
        site: t[supplierIndex],
        description: t[transactionDescriptionIndex],
        purchaseDate: t[transactionDateIndex],
      };
    }
    return undefined;
  });
};

const findAndMatch = (skyboxData, amexData) => {
  if (skyboxData) {
    skyboxData.forEach((skyboxRow) => {
      if (availableForMatch(skyboxRow)) {
        const filtered = filter(skyboxRow, amexData);
        match(skyboxRow, filtered[0]);
      }
    });
  }
};

const filter = (skyboxRow, amexData) => {
  const perfectMatch = amexData.filter((amexRow) => {
    if (!amexRow || !amexRow.confirmationNumber || amexRow.hasSkyboxPurchase) {
      return false;
    }
    return hasExactMatchConfirmationNumber(
      amexRow.confirmationNumber,
      skyboxRow.confirmationNumber
    );
  });
  if (perfectMatch.length > 0) {
    return perfectMatch;
  }

  const potential = amexData.filter((amexRow) => {
    if (!amexRow || !amexRow.confirmationNumber || amexRow.hasSkyboxPurchase) {
      return false;
    }
    return hasGoodConfirmationNumber(
      amexRow.confirmationNumber,
      skyboxRow.confirmationNumber
    );
  });
  if (potential.length > 0) {
    const sameLast4 = potential.filter((amexRow) =>
      hasSameLast4(amexRow, skyboxRow)
    );
    if (sameLast4.length > 0) {
      return sameLast4;
    }
    const sameByCost = potential.filter((amexRow) =>
      filterByCost([amexRow], skyboxRow.cost)
    );
    if (sameByCost.length > 0) {
      return sameByCost;
    }
  }
  return [];
};

const hasExactMatchConfirmationNumber = (
  ccConfirmationNumber,
  skyboxConfirmationNumber
) => {
  return (
    exactConfirmationNumberWithSlash(
      ccConfirmationNumber,
      skyboxConfirmationNumber
    ) ||
    exactAxsConfirmationNumber(ccConfirmationNumber, skyboxConfirmationNumber)
  );
};

const exactAxsConfirmationNumber = (
  ccConfirmationNumber,
  skyboxConfirmationNumber
) => {
  const ccmatch = ccConfirmationNumber.match(/(\d{9})\d+/i);
  if (ccmatch) {
    const isGoodConfirmationNumber = new RegExp(ccmatch[1]).test(
      skyboxConfirmationNumber
    );
    return isGoodConfirmationNumber;
  }
  return false;
};

const exactConfirmationNumberWithSlash = (
  ccConfirmationNumber,
  skyboxConfirmationNumber
) => {
  const ccMatch = ccConfirmationNumber.match(/(.*)\/(\d+)-(\d+)/i);
  if (ccMatch) {
    let reg = `${ccMatch[2]}-${ccMatch[3]}\/${ccMatch[1]}`;
    return new RegExp(reg).test(skyboxConfirmationNumber);
  }
  return false;
};

const hasGoodConfirmationNumber = (rocId, skyboxConfirmationNumber) => {
  return (
    confirmationNumberWithSlash(rocId, skyboxConfirmationNumber) ||
    confirmationNumberWithoutSalsh(rocId, skyboxConfirmationNumber) ||
    confirmationNumberAXS(rocId, skyboxConfirmationNumber)
  );
};

const confirmationNumberAXS = (rocId, skyboxConfirmationNumber) => {
  if (
    new RegExp(/\d{11}/).test(rocId) &&
    new RegExp(/\d{7,8}/).test(skyboxConfirmationNumber)
  ) {
    if (
      rocId.substr(0, 7) === skyboxConfirmationNumber ||
      rocId.substr(0, 8) === skyboxConfirmationNumber
    ) {
      return true;
    }
  }
  return false;
};

const confirmationNumberWithSlash = (rocId, skyboxConfirmationNumber) => {
  const rocIdMatch = rocId.match(/(.*)\/(\d+)-(\d+)/i);
  if (rocIdMatch) {
    let reg = `${rocIdMatch[2]}-${rocIdMatch[3]}\\d{0,1}\/${rocIdMatch[1]}`;
    if (new RegExp(reg, "i").test(skyboxConfirmationNumber)) {
      return true;
    }
  }
  return false;
};

const confirmationNumberWithoutSalsh = (rocId, skyboxConfirmationNumber) => {
  const rocIdMatch = rocId.match(/(\w{2}[A-z0-9]).{3,}(\d{2}-)/i);
  if (rocIdMatch) {
    const reg = `${rocIdMatch[2]}\\d{4,}\\/${rocIdMatch[1]}`;
    return new RegExp(reg, "i").test(skyboxConfirmationNumber);
  }
  return false;
};

const availableForMatch = (skyboxRow) => {
  return !skyboxRow.hasTransaction;
};

export default { findAndMatch, formatRawToObject };
