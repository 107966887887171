import React from "react";
import { AccountModel, CCModel } from "./ImportModels";

export default (props) => {
  const sortedAccountModel = AccountModel.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );
  const sortedCCModel = CCModel.sort((a, b) => (a.text > b.text ? 1 : -1));
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>Uploaded</div>
        <div>Account Fields</div>
        <div>Credit Card Fields</div>
      </div>
      {props.columns.map((c) => (
        <div
          key={`${c.indexColumn} ${c.columnName}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>{`${c.indexColumn + 1} ${c.columnName}`}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              className={"input"}
              value={c.accountField}
              onChange={(e) => props.onAccountFieldSelected(c, e.target.value)}
            >
              {sortedAccountModel.map((field) => {
                if (!field.isSelected) {
                  return (
                    <option
                      key={`${c.indexColumn} ${c.columnName} ${field.value}`}
                      value={field.value}
                    >
                      {field.text}
                    </option>
                  );
                }
                return;
              })}
            </select>
            <div style={{ display: "flex", alignItems: "center" }}>
              <select
                className={"input"}
                value={c.ccField}
                onChange={(e) => props.onCCFieldSelected(c, e.target.value)}
              >
                {sortedCCModel.map((field) => {
                  if (!field.isSelected) {
                    return (
                      <option
                        key={`${c.indexColumn} ${c.columnName} ${field.value}`}
                        value={field.value}
                      >
                        {field.text}
                      </option>
                    );
                  }
                  return;
                })}
              </select>
            </div>
            {c.error && (
              <span style={{ color: "red", fontSize: "20px" }}>!!!</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
