import React from "react";
import ReactTable from "react-table";
import LoadingIcon from "../../../components/LoadingIcon";
import EventHeader from "./EventHeader";
import SubTable from "../../../components/SubTable";
import ReactTableFilter from "../../../components/ReactTableFilter";

class AccountList extends React.Component {
  constructor() {
    super();
    this.state = { expanded: {}, platformFilters: [], stateFilters: [] };
    this.initState();
  }

  initState = async () => {
    const platformFilters = await this.loadDataFromApi(
      "/accounts/platformList"
    );
    const stateFilters = await this.loadDataFromApi("/provinces");
    this.setState({ platformFilters, stateFilters });
  };

  loadDataFromApi = async (apiUrl) => {
    try {
      const response = await fetch(`/api${apiUrl}`);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        let items = await response.json();
        return items;
      }
    } catch (err) {
      console.error(err);
    }
  };

  getData() {
    let rows = [];
    if (this.props.accounts != null) {
      this.props.accounts.forEach((acc) => {
        if (acc.events != null) {
          acc.events.forEach((evt) => {
            console.log(`"${evt.eventDate + evt.venue}" ${evt.quantity}`);
            acc[evt.eventDate + evt.venue.replace(/\./g, "")] = evt.quantity;
          });
        }
        rows.push(acc);
      });
    }
    return rows;
  }

  getColumns() {
    let columns = [
      {
        Header: "Platform",
        id: "platform",
        accessor: "info.platform",
        width: 65,
        filterMethod: (filter, row) => {
          if (
            filter.value === "all" ||
            filter.value === null ||
            !filter.value
          ) {
            return true;
          }
          let includesFilter = false;
          if (row._original && row._original.info.platform) {
            const platforms = row._original.info.platform
              .toUpperCase()
              .split(",");
            includesFilter = platforms.includes(filter.value.toUpperCase());
          }
          return includesFilter;
        },
        Filter: ({ filter, onChange }) => (
          <ReactTableFilter
            filter={filter}
            filters={this.state.platformFilters}
            optionValueProperty="DISTINCT"
            optionLabelProperty="DISTINCT"
            filterOptions={(items) => {
              return (
                items &&
                items.filter(
                  (item) =>
                    item && item.DISTINCT && !item.DISTINCT.includes(",")
                )
              );
            }}
            onFilterChange={(event) => {
              onChange(event.target.value);
            }}
          />
        ),
      },
      {
        Header: "State",
        id: "state",
        accessor: "info.state",
        width: 100,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return (
            filter.value == null ||
            !filter.value ||
            (row._original && row._original.info.state === filter.value)
          );
        },
        Filter: ({ filter, onChange }) => (
          <ReactTableFilter
            filter={filter}
            filters={this.state.stateFilters}
            optionValueProperty="state"
            optionLabelProperty="state"
            onFilterChange={(event) => {
              onChange(event.target.value);
            }}
          />
        ),
      },
      {
        Header: "Index",
        id: "index",
        accessor: "info.index",
        width: 50,
        sortMethod: (a, b) => {
          if (a == null) {
            return 1;
          }
          if (b == null) {
            return -1;
          }
          return a > b ? 1 : -1;
        },
      },
      {
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                background:
                  rowInfo.original.info && rowInfo.original.info.name
                    ? "#f0fff0"
                    : null,
              },
            };
          } else {
            return {};
          }
        },
        Header: "Email",
        accessor: "email",
        width: 185,
      },
    ];

    if (this.props.events != null) {
      this.props.events.forEach((event) => {
        console.log(
          "event.eventDate + event.venue",
          event.eventDate + event.venue
        );
        columns.push({
          Header: <EventHeader event={event} />,
          Cell: (props) => <div className="quantity">{props.value}</div>,
          id: event.eventDate + event.venue.replace(/\./g, ""),
          accessor: event.eventDate + event.venue.replace(/\./g, ""),
        });
      });
    }
    return columns;
  }

  handleRowExpanded(index) {
    if (this.state.expanded[index] === true) {
      this.setState({
        expanded: { [index]: false },
      });
    } else {
      this.setState({
        expanded: { [index]: true },
      });
    }
  }

  render() {
    let columns = this.getColumns();
    let rows = this.getData();

    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px - 70px)" }}
        loading={this.props.loading}
        loadingText={<LoadingIcon />}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
        expanded={this.state.expanded}
        data={rows}
        columns={columns}
        SubComponent={(row) => {
          const account = this.props.accounts[row.row._index];
          return <SubTable quantities={account} data={account.info} />;
        }}
        onExpandedChange={(newExpanded, index, event) =>
          this.handleRowExpanded(index)
        }
        defaultSorted={[
          {
            id: "index",
            desc: false,
          },
        ]}
        defaultPageSize={100}
      />
    );
  }
}

export default AccountList;
