import React, { Component } from "react";
import axios from "axios";
import FilterBar from "./components/FilterBar";
import amexMatcher from "./logic/amexMatcher";
import comdataMatcher from "./logic/comdataMatcher";
import bmoMatcher from "./logic/bmoMatcher";
import TabPresenter from "./components/TabPresenter";
import { formatSkyboxDate } from "../../utils/skyboxUtils";

class Accounting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      purchaseDateFrom: "",
      purchaseDateTo: "",
      skyboxData: [],
      amexUsdData: [],
      amexCadData: [],
      comdataUsdData: [],
      comdataCadData: [],
      bmoUsdData: [],
      bmoCadData: [],
    };
  }

  async generateReport(purchaseDateFrom, purchaseDateTo) {
    console.log(`purchaseDateFrom`, purchaseDateFrom);
    this.setState({
      loading: true,
      purchaseDateFrom: encodeURI(formatSkyboxDate(purchaseDateFrom, true)),
      purchaseDateTo: encodeURI(formatSkyboxDate(purchaseDateTo, false)),
    });
    console.log(`purchaseDateFrom`, purchaseDateFrom);
    const result = await this.callApi(
      `/api/accounting/?purchaseDateFrom=${purchaseDateFrom}&purchaseDateTo=${purchaseDateTo}`
    );
    if (result) {
      if (this.state.amexUsdData.length > 0) {
        amexMatcher.findAndMatch(result.skyboxData, this.state.amexUsdData);
      }
      if (this.state.amexCadData.length > 0) {
        amexMatcher.findAndMatch(result.skyboxData, this.state.amexCadData);
      }
      if (this.state.comdataUsdData.length > 0) {
        comdataMatcher.findAndMatch(
          result.skyboxData,
          this.state.comdataUsdData
        );
      }
      if (this.state.comdataCadData.length > 0) {
        comdataMatcher.findAndMatch(
          result.skyboxData,
          this.state.comdataCadData
        );
      }
      if (this.state.bmoUsdData.length > 0) {
        bmoMatcher.findAndMatch(result.skyboxData, this.state.bmoUsdData);
      }
      if (this.state.bmoCadData.length > 0) {
        bmoMatcher.findAndMatch(result.skyboxData, this.state.bmoCadData);
      }
      console.log("result.skyboxData", result.skyboxData);
      console.log("result.flipData", result.flipData);
      this.setState({
        skyboxData: result.skyboxData,
        flipData: result.flipData,
        loading: false,
      });
    }
  }

  async callApi(url) {
    this.setState({ loading: true });
    try {
      const response = await axios.get(url, { timeout: 0 });
      this.setState({ loading: false });
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
    return [];
  }

  async onSearch(purchaseDateFrom, purchaseDateTo) {
    await this.generateReport(purchaseDateFrom, purchaseDateTo);
  }

  handleUploadAmexUsd(amexData) {
    this.handleUploadAmex(amexData, "amexUsdData");
  }

  handleUploadAmexCad(amexData) {
    this.handleUploadAmex(amexData, "amexCadData");
  }

  handleUploadAmex(amexData, field) {
    let formatted;
    try {
      formatted = amexMatcher.formatRawToObject(amexData);
      console.log(field, formatted);
    } catch (error) {
      alert(
        "There's something wrong with the file you use. Maybe you imported an Amex file in the Comdata uploader or vice-versa"
      );
      console.log("error", error);
      return;
    }
    if (this.state.skyboxData) {
      this.setState({ [field + "matching"]: true });
      amexMatcher.findAndMatch(this.state.skyboxData, formatted);
    }
    this.setState({ [field]: formatted, [field + "matching"]: false });
  }

  handleUploadComdataUsd(comdataData) {
    console.log("comdataData", comdataData);
    this.handleUploadComdata(comdataData, "comdataUsdData");
  }

  handleUploadComdataCad(comdataData) {
    this.handleUploadComdata(comdataData, "comdataCadData");
  }

  handleUploadComdata(comdataData, field) {
    let formatted;
    try {
      formatted = comdataMatcher.formatRawToObject(comdataData);
      console.log(field, formatted);
    } catch (error) {
      alert(
        "There's something wrong with the file you use. Maybe you imported an Amex file in the Comdata uploader or vice-versa"
      );
      console.log("error", error);
      return;
    }
    if (this.state.skyboxData) {
      this.setState({ [field + "matching"]: true });
      comdataMatcher.findAndMatch(this.state.skyboxData, formatted);
    }
    this.setState({ [field]: formatted, [field + "matching"]: false });
  }

  handleUploadBMOUsd(bmoData) {
    this.handleUploadBMO(bmoData, "bmoUsdData");
  }

  handleUploadBMOCad(bmoData) {
    this.handleUploadBMO(bmoData, "bmoCadData");
  }

  handleUploadBMO(bmoData, field) {
    let formatted;
    try {
      formatted = bmoMatcher.formatRawToObject(bmoData);
      console.log(field, formatted);
    } catch (error) {
      alert(
        "There's something wrong with the file you use. Maybe you imported an Amex file in the Comdata uploader or vice-versa"
      );
      console.log("error", error);
      return;
    }
    if (this.state.skyboxData) {
      this.setState({ [field + "matching"]: true });
      bmoMatcher.findAndMatch(this.state.skyboxData, formatted);
    }
    this.setState({ [field]: formatted, [field + "matching"]: false });
  }

  render() {
    return (
      <div>
        <FilterBar
          onSearch={this.onSearch.bind(this)}
          onAmexUsdUpload={this.handleUploadAmexUsd.bind(this)}
          onAmexCadUpload={this.handleUploadAmexCad.bind(this)}
          onComdataUsdUpload={this.handleUploadComdataUsd.bind(this)}
          onComdataCadUpload={this.handleUploadComdataCad.bind(this)}
          onBMOUsdUpload={this.handleUploadBMOUsd.bind(this)}
          onBMOCadUpload={this.handleUploadBMOCad.bind(this)}
        />
        <TabPresenter
          data={this.state}
          purchaseDateFrom={this.state.purchaseDateFrom}
          purchaseDateTo={this.state.purchaseDateTo}
        />
      </div>
    );
  }
}

export default Accounting;
