import React, { Component } from "react";
import ModemList from "./components/ModemList";
import FilterList from "../../components/FilterList";

class ModemPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modems: [],
      accounts: [],
      fields: [
        { value: "index", label: "Index" },
        { value: "modemId", label: "Modem" },
        { value: "distributick", label: "Distributick" },
        { value: "phoneNumber", label: "Phone Number" },
        { value: "email", label: "Email" },
        { value: "operator", label: "Operator" },
        { value: "port", label: "COMPort" },
        { value: "isOpen", label: "Is Open" },
        { value: "isListening", label: "Is Listening" },
        { value: "lastUpdated", label: "Last updated" }
      ]
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
    this.listen();
    this.startListening();
  }

  componentWillUnmount() {
    this.stopListening();
  }

  stopListening() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  async startListening() {
    const interval = setInterval(() => {
      this.listen();
    }, 3000);
    this.setState({ interval: interval });
  }

  async getAccounts() {
    this.setState({ loading: true });
    const URL = `/api/accounts`;
    const result = await this.callApi(URL);
    if (result) {
      const modems = this.mergeAccountToModems(this.state.modems, result);
      this.setState({ loading: false, modems, accounts: result });
    } else {
      this.setState({ loading: false });
    }
  }

  async listen() {
    this.setState({ loading: true });
    const URL = `${process.env.REACT_APP_SMSGATE_API_URL}/api/modem/all`;
    const result = await this.callApi(URL);
    if (result) {
      const modems = this.mergeAccountToModems(result, this.state.accounts);
      this.setState({ loading: false, modems });
    } else {
      this.setState({ loading: false });
    }
  }

  mergeAccountToModems(modemList, accounts) {
    if (!modemList) {
      return [];
    }
    return modemList.map(modem => {
      if (modem.phoneNumber) {
        const account = accounts.find(a => a.phone === modem.phoneNumber);
        if (account) {
          return {
            ...modem,
            distributick: account.index,
            email: account.email
          };
        }
      }
      return modem;
    });
  }

  async callApi(url) {
    try {
      const response = await fetch(url);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        return await response.json();
      }
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
  }

  handleFilter(filtered) {
    this.setState({ filteredModem: filtered });
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "70%" }}>
          <ModemList data={this.state.filteredModem || this.state.modems} />
        </div>
        <div style={{ width: "30%" }}>
          <FilterList
            hasCodeFilter={false}
            filterName="modem"
            fields={this.state.fields}
            data={this.state.modems}
            filterChanged={this.handleFilter}
          />
        </div>
      </div>
    );
  }
}

export default ModemPage;
