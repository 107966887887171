import React, {Component} from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';

class Sidebar extends Component {

  render() {
    let sidebarClass = classNames({
      'sidebar': true,
      'sidebar--show': false,
      'sidebar--collapse': false
    });


    return (
      <div className={sidebarClass}>
        <div className='sidebar__back'/>
        <Scrollbar className='sidebar__scroll scroll'>
          <div className='sidebar__wrapper sidebar__wrapper--desktop'>
            <SidebarContent user={this.props.user}/>
          </div>
        </Scrollbar>
      </div>
    )
  }
}

export default Sidebar;