import React, { useState } from "react";
import Autocomplete from "./Autocomplete";

export default function AutoCompleteField(props) {
  const [field, setField] = useState({ name: "" });

  function handleChange(v) {
    if (v.id) {
      props.onSelectionChange(v);
    } else {
      props.onSelectionChange({});
    }
    setField(v);
  }

  return (
    <div className="search-field">
      <Autocomplete
        onChange={(v) => handleChange(v)}
        suggestions={props.suggestions}
        placeholder={props.placeholder}
        value={field.name}
      />
    </div>
  );
}
