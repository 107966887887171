import React, { Component } from "react";
import ReactTable from "react-table";
import SubTable from "../../../components/SubTable";
import LoadingIcon from "../../../components/LoadingIcon";
import moment from "moment-timezone";
import ReactTableFilter from "../../../components/ReactTableFilter";

class SkyboxTable extends Component {
  getColumns() {
    return [
      {
        Header: "Matched",
        id: "hasTransaction",
        accessor: (row) => row.hasTransaction.toString(),
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.original) {
            return {
              style: {
                background: !rowInfo.original.hasTransaction ? "red" : "green",
              },
            };
          } else {
            return {};
          }
        },
        width: 65,
      },
      {
        Header: "Purchase Date",
        id: "purchaseDateTime",
        accessor: (row) =>
          moment.tz(row.purchaseDateTime, "UTC").format("YYYY-MM-DD HH:mm:ss"),
        width: 132,
      },
      {
        Header: "Credit Card",
        id: "creditCard",
        accessor: "creditCard",
        width: 132,
      },
      {
        Header: "Status",
        id: "status",
        accessor: "status",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return (
            filter.value == null ||
            !filter.value ||
            (row._original && row._original.status === filter.value)
          );
        },
        Filter: ({ filter, onChange }) => (
          <ReactTableFilter
            filter={filter}
            filters={this.props.data || []}
            optionValueProperty="status"
            optionLabelProperty="status"
            onFilterChange={(event) => {
              onChange(event.target.value);
            }}
          />
        ),
        width: 132,
      },
      {
        Header: "Event Date",
        id: "eventDateTime",
        accessor: (row) =>
          moment.tz(row.eventDateTime, "UTC").format("YYYY-MM-DD HH:mm"),
        width: 132,
      },
      {
        Header: "Event Name",
        id: "event",
        accessor: "event",
      },
      {
        Header: "Confirmation #",
        id: "confirmationNumber",
        accessor: "confirmationNumber",
        width: 132,
      },
      {
        Header: "Cost",
        id: "cost",
        accessor: (row) => parseFloat(row.cost).toFixed(2),
        getProps: (state, rowInfo) => {
          const baseStyle = {
            style: { textAlign: "right", paddingRight: "20px" },
          };
          if (rowInfo && rowInfo.original && rowInfo.original.transaction) {
            const transaction = rowInfo.original.transaction;
            const skyboxCost = parseFloat(rowInfo.original.cost);
            baseStyle.style.background = !transaction
              ? null
              : parseFloat(transaction.totalCost) === skyboxCost
              ? "green"
              : parseFloat(transaction.totalCostWithFee) === skyboxCost
              ? "#00d400"
              : parseFloat(transaction.unitCost) === skyboxCost
              ? "orange"
              : "red";
          }
          return baseStyle;
        },
        Footer: (row) => `Total: ${row.cost}`,
        width: 132,
      },
    ];
  }

  render() {
    return (
      <div>
        {this.props.loading}
        <ReactTable
          style={{ minHeight: "calc(100vh - 60px - 114px - 46px)" }}
          filterable
          loading={this.props.loading}
          loadingText={<LoadingIcon />}
          defaultFilterMethod={(filter, row) => {
            let filterValue = filter.value;
            let endWithSlash;
            do {
              endWithSlash = filterValue[filterValue.length - 1] === "\\";
              if (endWithSlash) {
                filterValue = filterValue.slice(0, -1);
              }
            } while (endWithSlash);

            const re = new RegExp(filterValue, "i");
            return re.test(String(row[filter.id]));
          }}
          data={this.props.data || []}
          SubComponent={(row) => {
            if (!!row.original.transaction) {
              return <SubTable data={row.original.transaction} />;
            }
            return null;
          }}
          columns={this.getColumns()}
          defaultSorted={[
            {
              id: "purchaseDateTime",
              desc: false,
            },
          ]}
          defaultPageSize={100}
        />
      </div>
    );
  }
}

export default SkyboxTable;
