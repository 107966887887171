const moment = require("moment-timezone");

module.exports.formatSkyboxDate = (date, isFrom) => {
  let formattedDate = moment(date).format("YYYY-MM-DD");
  formattedDate = moment(formattedDate).tz("UTC");
  if (isFrom) {
    return formattedDate.format("YYYY-MM-DDTHH:00:00.000") + "Z";
  }
  return (
    formattedDate.add(-1, "second").format("YYYY-MM-DDTHH:59:59.999") + "Z"
  );
};
