import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
    };
  }

  onChange = (e) => {
    this.props.onChange({ name: e.currentTarget.value });
    const filteredSuggestions = this.filterSuggestions(e.currentTarget.value);
    this.setState({
      activeSuggestion: -1,
      filteredSuggestions,
      showSuggestions: true,
    });
  };

  onClick = (suggestion) => {
    this.props.onChange(suggestion);
    this.setState({
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
    });
    return true;
  };

  onKeyDown = (e) => {
    const { showSuggestions, activeSuggestion, filteredSuggestions } =
      this.state;
    if (e.key === "Enter") {
      this.setState(
        {
          activeSuggestion: -1,
          showSuggestions: false,
        },
        async () => {
          if (filteredSuggestions[activeSuggestion]) {
            await this.props.onChange(filteredSuggestions[activeSuggestion]);
          }
          if (this.props.onEnter) {
            this.props.onEnter();
          }
        }
      );
    } else if (e.key === "ArrowUp") {
      if (activeSuggestion === -1) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.key === "ArrowDown") {
      if (!showSuggestions) {
        this.showSuggestionBox(this.props);
      }
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    } else if (e.key === "Tab") {
      if (activeSuggestion > -1 && filteredSuggestions.length > 0) {
        this.props.onChange(filteredSuggestions[activeSuggestion]);
      }
      this.hideSuggestionBox();
    } else if (e.key === "Escape") {
      this.hideSuggestionBox();
    }
  };

  hideSuggestionBox() {
    this.setState({
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
    });
  }

  filterSuggestions(userInput) {
    const { suggestions } = this.props;
    const regex = new RegExp("\\b" + userInput, "i");
    return suggestions.filter((suggestion) => regex.test(suggestion.name));
  }

  showSuggestionBox({ value }) {
    const filteredSuggestions = this.filterSuggestions(value);
    this.setState({ showSuggestions: true, filteredSuggestions });
  }

  render() {
    const { activeSuggestion, filteredSuggestions, showSuggestions } =
      this.state;
    const { value, placeholder } = this.props;

    let suggestionsListComponent;

    if (showSuggestions && value && filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className={"suggestions"}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li
                className={className}
                key={suggestion.name}
                onClick={() => this.onClick(suggestion)}
              >
                {suggestion.name}
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <Fragment>
        <input
          type="text"
          className={"input"}
          placeholder={placeholder}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={value}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
