import React, { Component } from "react";
import Filter from "../components/Filter";

class FilterList extends Component {
  constructor(props) {
    super(props);
    let localStorageFilter = localStorage.getItem(
      `filter-${this.props.filterName}`
    );
    let filters = localStorageFilter
      ? JSON.parse(localStorageFilter)
      : [this.emptyFilter()];
    this.state = { filters, filterCode: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleFilterCode = this.handleFilterCode.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps && !this.props.data) ||
      JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
    ) {
      this.setState({ ...this.state, ...nextProps.data }, () => this.filter());
    }
  }

  emptyFilter() {
    return { field: "", operation: "", value: "" };
  }

  filter() {
    const data = this.props.data;
    const { filters, filterCode } = this.state;
    const filtered = data.filter(m => {
      if (filterCode && !/^[A-z0-9]{7,12}$/.test(m.message)) {
        return false;
      }
      for (let indexFilter = 0; indexFilter < filters.length; indexFilter++) {
        const filter = filters[indexFilter];
        if (filter.field && filter.operation && filter.value) {
          const operation = filter.operation.value;
          const value = String(m[filter.field.value]).toUpperCase();
          const filterValue = String(filter.value).toUpperCase();
          if (operation === "%%") {
            if (!value.includes(filterValue)) {
              return false;
            }
          } else if (operation === "<>") {
            if (value.includes(filterValue)) {
              return false;
            }
          } else if (operation === "===") {
            if (value !== filterValue) {
              return false;
            }
          } else if (operation === "*%") {
            if (!value.startsWith(filterValue)) {
              return false;
            }
          } else if (operation === "%*") {
            if (!value.endsWith(filterValue)) {
              return false;
            }
          } else if (operation === "len") {
            if (value.length !== parseInt(filterValue, 10)) {
              return false;
            }
          }
        }
      }
      return true;
    });
    this.props.filterChanged(filtered);
  }

  handleChange(filter, index) {
    const filters = this.state.filters;
    filters[index] = filter;
    this.saveFilters(filters);
    this.filter();
  }

  handleEnter(filter, index) {
    this.filter();
  }

  handleRemove(filter, index) {
    const filters = this.state.filters.filter(f => f !== filter);
    this.saveFilters(filters);
    this.filter();
  }

  handleFilterCode() {
    this.setState(
      prevState => ({ filterCode: !prevState.filterCode }),
      () => {
        this.filter();
      }
    );
  }

  addFilter() {
    const filters = this.state.filters;
    filters.push(this.emptyFilter());
    this.saveFilters(filters);
    this.filter();
  }

  saveFilters(filters) {
    this.setState({ filters });
    localStorage.setItem(
      `filter-${this.props.filterName}`,
      JSON.stringify(filters)
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {this.state.filters &&
          this.state.filters.map((f, index) => {
            return (
              <Filter
                key={`${f.field}-${index}`}
                index={index}
                filter={f}
                fields={this.props.fields}
                onChange={this.handleChange}
                onEnter={this.handleEnter}
                onRemove={this.handleRemove}
              />
            );
          })}
        <button onClick={this.addFilter.bind(this)}>+</button>
        {this.props.hasCodeFilter && (
          <div>
            <input
              name="filterCode"
              type="checkbox"
              checked={this.state.filterCode}
              onChange={this.handleFilterCode}
            />
            <span>Only codes</span>
          </div>
        )}
      </div>
    );
  }
}
export default FilterList;
