import React, { Component } from "react";
import { CSVLink } from "react-csv/lib";
import LoadingIcon from "../../components/LoadingIcon";
import moment from "moment-timezone";

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, loadingLastExecution: false };
  }

  componentDidMount() {
    this.getLastExecution();
  }

  async getLastExecution() {
    const URL = "/api/coachella/lastExecutionTime";
    this.setState({ loadingLastExecution: true });
    const lastExecution = await this.callApi(URL);
    this.setState({ lastExecution, loadingLastExecution: false });
  }

  async handleGetCsv() {
    const URL = "/api/coachella/report";
    this.setState({ loading: true });
    const data = await this.callApi(URL);
    this.setState({ data, loading: false });
  }

  async callApi(url) {
    try {
      const response = await fetch(url);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        return await response.json();
      }
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
  }

  render() {
    const filename = moment().format("YYYY-MM-DD") + ".csv";
    const style = { margin: "20px" };
    const lastExecution = moment(this.state.lastExecution)
      .tz("America/Toronto")
      .format("YYYY-MM-DD HH:mm:ss");

    return (
      <div>
        <div>
          <span>Last inserted sale : </span>
          <span>{!!this.state.lastExecution && lastExecution}</span>
        </div>
        <button
          className={"btn btn-outline-primary"}
          onClick={this.handleGetCsv.bind(this)}
          style={style}
        >
          Generate csv
        </button>
        {(this.state.loading || this.state.loadingLastExecution) && (
          <LoadingIcon />
        )}
        <div>
          {!!this.state.data && !!this.state.data.w1 && (
            <CSVLink
              data={this.state.data.w1}
              filename={"coachellaW1-" + filename}
              style={{marginRight:"15px"}}
            >
              Download W1
            </CSVLink>
          )}
          {!!this.state.data && !!this.state.data.w2 && (
            <CSVLink
              data={this.state.data.w2}
              filename={"coachellaW2-" + filename}
            >
              Download W2
            </CSVLink>
          )}
        </div>
      </div>
    );
  }
}

export default Accounts;
