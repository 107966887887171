import React, { Component } from "react";
import LogInForm from "./components/LogInForm";

export default class LogIn extends Component {
  render() {
    return (
      <div className="login">
        <div className="login__wrapper">
          <div className="login__card">
            <div className="login__head">
              <h3 className="login__title">
                Welcome to <span className="login__logo">Karlos</span>
              </h3>
            </div>
            <LogInForm onSubmit />
          </div>
        </div>
      </div>
    );
  }
}

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
