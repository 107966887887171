import React, { Component } from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import CSVReader from "react-csv-reader";
import "react-datepicker/dist/react-datepicker.css";

class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = { purchaseDateFrom: undefined, purchaseDateTo: undefined };
  }

  handleChangeFromDate(event) {
    this.handleChangeDate(event, "purchaseDateFrom");
  }

  handleChangeToDate(event) {
    this.handleChangeDate(event, "purchaseDateTo");
  }

  handleChangeDate(event, fromToField) {
    if (event != null) {
      let date = moment(event).format("YYYY-MM-DD 00:00:00");
      this.setState({ [fromToField]: date });
      localStorage.setItem(`accounting_${fromToField}`, date);
    } else {
      this.setState({ [fromToField]: undefined });
      localStorage.removeItem(`accounting_${fromToField}`);
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  handleSearch() {
    this.props.onSearch(this.state.purchaseDateFrom, this.state.purchaseDateTo);
  }

  render() {
    const {
      handleSearch,
      handleChangeFromDate,
      handleChangeToDate,
      handleKeyPress,
      state: { purchaseDateFrom, purchaseDateTo },
    } = this;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="search-field">
          <fieldset>
            <legend>Skybox Data:</legend>
            <div className="search-field">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "50px" }}>FROM:</div>
                <DatePicker
                  className={"input inputmedium"}
                  id="date-field-from"
                  autocomplete="off"
                  selected={
                    purchaseDateFrom ? new Date(purchaseDateFrom) : null
                  }
                  onChange={handleChangeFromDate.bind(this)}
                  onKeyPress={handleKeyPress.bind(this)}
                  popperPlacement="right-start"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "50px" }}>TO:</div>
                <DatePicker
                  className={"input inputmedium"}
                  id="date-field-to"
                  autocomplete="off"
                  selected={purchaseDateTo ? new Date(purchaseDateTo) : null}
                  onChange={handleChangeToDate.bind(this)}
                  onKeyPress={handleKeyPress.bind(this)}
                  popperPlacement="right-start"
                />
              </div>
            </div>
            <div className="search-field">
              <button
                className={"btn btn-outline-primary btn-large"}
                onClick={handleSearch.bind(this)}
              >
                Search
              </button>
            </div>
          </fieldset>
        </div>
        <div className="search-field">
          <fieldset>
            <legend>Amex:</legend>
            USD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onAmexUsdUpload(data)}
            />
            CAD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onAmexCadUpload(data)}
            />
          </fieldset>
        </div>
        <div className="search-field">
          <fieldset>
            <legend>Comdata:</legend>
            USD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onComdataUsdUpload(data)}
            />
            CAD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onComdataCadUpload(data)}
            />
          </fieldset>
        </div>
        <div className="search-field">
          <fieldset>
            <legend>BMO:</legend>
            USD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onBMOUsdUpload(data)}
            />
            CAD:{" "}
            <CSVReader
              onFileLoaded={(data) => this.props.onBMOCadUpload(data)}
            />
          </fieldset>
        </div>
      </div>
    );
  }
}

export default FilterBar;
