const {
  hasSameLast4,
  filterByCost,
  match,
  findColumnIndex,
} = require("./transactionMatcher");

const formatRawToObject = (bmoData) => {
  const headerRow = bmoData[0];
  const last4Index = findColumnIndex(headerRow, "Account - Last Four Digits");
  const costIndex = findColumnIndex(headerRow, "Transaction - Source Amount");
  const supplierIndex = findColumnIndex(headerRow, "Supplier - Name");
  const transactionDateIndex = findColumnIndex(
    headerRow,
    "Transactio",
    findColumnIndex(headerRow, "Transactio")
  );

  let formattedData = bmoData.map((t) => {
    if (t.length > 1) {
      let last4 = t[last4Index];
      if (last4.length === 3) {
        last4 = `0${last4}`;
      }
      return {
        totalCost: parseFloat(
          t[costIndex].replace("$", "").replace(/,/g, "")
        ).toFixed(2),
        site: t[supplierIndex],
        creditCard: last4,
        purchaseDate: t[transactionDateIndex],
      };
    } else {
      return undefined;
    }
  });
  formattedData = formattedData.splice(1, formattedData.length - 2);
  return formattedData;
};

const findAndMatch = (skyboxData, bmoData) => {
  if (skyboxData) {
    skyboxData.forEach((skyboxRow) => {
      if (availableForMatch(skyboxRow)) {
        const filtered = filter(skyboxRow, bmoData);
        match(skyboxRow, filtered[0]);
      }
    });
  }
};

const filter = (skyboxRow, bmoData) => {
  const sameLast4AndCC = bmoData.filter(
    (bmoRow) =>
      !bmoRow.hasSkyboxPurchase &&
      hasSameLast4(bmoRow, skyboxRow) &&
      filterByCost([bmoRow], skyboxRow.cost)
  );
  if (sameLast4AndCC.length > 0) {
    return sameLast4AndCC;
  }
  return [];
};

const availableForMatch = (skyboxRow) => {
  return !skyboxRow.hasTransaction;
};

export default { findAndMatch, formatRawToObject };
