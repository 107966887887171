const {
  hasSameLast4,
  findColumnIndex,
  match,
  filterByCost,
} = require("./transactionMatcher");

const formatRawToObject = (comdataData) => {
  const headerRow = comdataData[0];
  const creditCardIndex = findColumnIndex(headerRow, "Card Number");
  const costIndex = findColumnIndex(headerRow, "Posted Amount");
  const preAuthCostIndex = findColumnIndex(
    headerRow,
    "Pre-Authorization Amount"
  );
  const supplierIndex = findColumnIndex(headerRow, "Merchant Name");
  const transactionDateIndex = findColumnIndex(headerRow, "Transaction Date");
  const statusIndex = findColumnIndex(headerRow, "Transaction Status");
  const descriptionIndex = findColumnIndex(headerRow, "Accept Location");

  let formattedData = comdataData.map((t) => {
    const postedCost = parseCost(t[costIndex]);
    const preAuthCost = parseCost(t[preAuthCostIndex]);
    let cost = postedCost === 0 || isNaN(postedCost) ? preAuthCost : postedCost;
    return {
      totalCost: cost,
      site: t[supplierIndex],
      creditCard: t[creditCardIndex],
      purchaseDate: t[transactionDateIndex],
      status: t[statusIndex],
      description: t[descriptionIndex],
    };
  });
  formattedData = formattedData.splice(1, formattedData.length - 2);
  return formattedData;
};

const parseCost = (value) => {
  let result = (value || "").replace("$", "").replace(/,/g, "");
  if (result.includes("(")) {
    result = result.replace("(", "-").replace(")", "");
  }
  return parseFloat(result);
};

const findAndMatch = (skyboxData, comdataData) => {
  if (skyboxData) {
    skyboxData.forEach((skyboxRow) => {
      if (
        availableForMatch(skyboxRow) &&
        (comdataData.status || "").toLowerCase() !== "declined"
      ) {
        const filtered = filter(skyboxRow, comdataData);
        match(skyboxRow, filtered[0]);
      }
    });
  }
};

const filter = (skyboxRow, comdataData) => {
  const sameLast4AndCost = comdataData.filter(
    (comdataRow) =>
      !comdataRow.hasSkyboxPurchase &&
      hasSameLast4(comdataRow, skyboxRow) &&
      filterByCost([comdataRow], skyboxRow.cost)
  );
  if (sameLast4AndCost.length > 0) {
    return sameLast4AndCost;
  }
  return [];
};

const availableForMatch = (skyboxRow) => {
  return !skyboxRow.hasTransaction;
};

export default { findAndMatch, formatRawToObject };
