import React, {Component} from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarMenuLink from './TopbarMenuLink';
import {Collapse} from 'reactstrap';

export default class TopbarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  render() {
    return (
      <div className='topbar__profile'>
        <div className='topbar__avatar' onClick={this.toggle}>
          <img className='topbar__avatar-img' src={this.props.user.image.url} alt='avatar'/>
          <p className='topbar__avatar-name'>{this.props.user.displayName}</p>
          <DownIcon className='topbar__icon'/>
        </div>
          {this.state.collapse && <div className='topbar__back' onClick={this.toggle}/>}
          <Collapse isOpen={this.state.collapse} className='topbar__menu-wrap'>
              <div className='topbar__menu'>
                  <TopbarMenuLink title='My Profile' icon='user' path='/'/>
                  <TopbarMenuLink title='Favorites' icon='calendar-full' path='/'/>
                  <div className='topbar__menu-divider'/>
                  <TopbarMenuLink title='Log Out' icon='exit' path='/logout'/>
              </div>
          </Collapse>
      </div>
    )
  }
}
