import React, { Component } from "react";

export default class InputEnterAction extends Component {
  handleKeyPress(e) {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(e);
    }
    if (e.key === "Enter" && this.props.onEnter) {
      this.props.onEnter();
    }
  }

  render() {
    let { onEnter, ...props } = this.props;
    return (
      <input
        className={"input"}
        {...props}
        onKeyPress={this.handleKeyPress.bind(this)}
      />
    );
  }
}
