import React from "react";
import ReactTable from "react-table";
import LoadingIcon from "../../../components/LoadingIcon";
import moment from "moment-timezone";

class SmsList extends React.Component {
  getColumns() {
    let columns = [
      {
        Header: "Date Received",
        id: "dateTimeSent",
        accessor: "dateTimeSent",
        Cell: row => (
          <span>
            {moment.tz(row.value, "UTC").format("YYYY-MM-DD HH:mm:ss")}
          </span>
        ),
        width: 132
      },
      {
        Header: "Port",
        id: "comport",
        accessor: "comport",
        width: 45,
        filterMethod: (filter, row) =>
          String(row[filter.id]).toUpperCase() === filter.value.toUpperCase()
      },
      {
        Header: "Modem",
        id: "modemId",
        accessor: "name",
        width: 85,
        filterMethod: (filter, row) =>
          String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())
      },
      {
        Header: "Distributick",
        id: "distributick",
        accessor: "distributick",
        width: 90,
        filterMethod: (filter, row) =>
          String(row[filter.id]).toUpperCase() === filter.value.toUpperCase()
      },
      {
        Header: "Phone Number",
        id: "to",
        accessor: "to",
        headerStyle: { textAlign: "center" },
        width: 115
      },
      {
        Header: "From",
        id: "from",
        accessor: "from",
        headerStyle: { textAlign: "center" },
        width: 115
      },
      {
        Header: "Email",
        id: "email",
        accessor: "email",
        width: 160
      },
      {
        Header: "Message",
        id: "message",
        accessor: "message",
        Cell: row => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-all"
            }}
          >
            {row.value}
          </div>
        )
      },
    ];
    return columns;
  }

  render() {
    let columns = this.getColumns();
    const rows = this.props.data;

    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px)" }}
        loading={this.props.loading}
        loadingText={<LoadingIcon />}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
        data={rows}
        columns={columns}
        defaultSorted={[
          {
            id: "dateTimeSent",
            desc: true
          }
        ]}
        defaultPageSize={100}
      />
    );
  }
}

export default SmsList;
