import React from "react";
import axios from "axios";
import PromptEditable from "../../../components/PromptEditable";

export default function (props) {
  const deniedAccontList = JSON.parse(
    process.env.REACT_APP_ACCOUNT_LIST_1TICKET_DISABLED
  );
  const renderPromptEditable = (cellInfo) => {
    return (
      <PromptEditable
        item={props.account}
        cellInfo={cellInfo}
        itemChanged={itemChanged}
      />
    );
  };

  const itemChanged = async (item) => {
    props.onUpdateAccount();
    await axios.post("/api/accounts/", item);
    props.onAccountUpdated();
  };

  const canSyncTo1Ticket = () => {
    return !props.account.account_list_accounts.some((ala) =>
      deniedAccontList.includes(ala.accountListId)
    );
  };

  const syncPasswordWithOneTicket = async () => {
    const result = await axios.post("/api/oneticket", {
      email: props.account.email,
      password: props.account.password,
    });
    props.onOneTicketUpdated(props.account.email, result.data);
  };

  return (
    <ul className="flex-outer">
      <li>
        <label htmlFor="index">Index</label>
        <div>
          {renderPromptEditable({
            value: props.account.index,
            column: {
              Header: "Index",
              id: "index",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="email">Email</label>
        <span id="email">{props.account.email}</span>
      </li>
      <li>
        <label htmlFor="password">Password</label>
        <div>
          {renderPromptEditable({
            value: props.account.password,
            column: {
              Header: "Password",
              id: "password",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="1ticket">1Ticket Synced</label>
        <div>
          {props.oneTicketStatus && props.account.oneTicketSynced ? (
            <img
              src="/img/greenCheckmark.png"
              alt="synced"
              style={{ width: "25px", height: "25px" }}
            />
          ) : canSyncTo1Ticket() ? (
            <button
              className="btn btn-outline-primary btn-xsm"
              onClick={() => syncPasswordWithOneTicket()}
            >
              Sync
            </button>
          ) : (
            <span>DS</span>
          )}
        </div>
      </li>
      <li>
        <label htmlFor="1ticketStatus">1Ticket Status</label>
        <span id="1ticketStatus">
          {!canSyncTo1Ticket() ? (
            "Should not be in 1Ticket"
          ) : !props.oneTicketStatus ? (
            "Not Found"
          ) : props.oneTicketStatus.error &&
            props.oneTicketStatus.error.error_description ? (
            <div className="longText" style={{ background: "#FF8282" }}>
              Error: {props.oneTicketStatus.error.error_description}
            </div>
          ) : !props.oneTicketStatus.active ? (
            "Inactive"
          ) : (
            "All Good"
          )}
        </span>
      </li>
      <li>
        <label htmlFor="Name">Name</label>
        <div>
          {renderPromptEditable({
            value: props.account.name,
            column: {
              Header: "Name",
              id: "name",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Phone">Phone</label>
        <div>
          {renderPromptEditable({
            value: props.account.phone,
            column: {
              Header: "Phone",
              id: "phone",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Address">Address</label>
        <div>
          {renderPromptEditable({
            value: props.account.address,
            column: {
              Header: "Address",
              id: "address",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="City">City</label>
        <div>
          {renderPromptEditable({
            value: props.account.city,
            column: {
              Header: "City",
              id: "city",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Metro">Metro</label>
        <div>
          {renderPromptEditable({
            value: props.account.metro,
            column: {
              Header: "Metro",
              id: "metro",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Postal Code">Postal Code</label>
        <div>
          {renderPromptEditable({
            value: props.account.postalCode,
            column: {
              Header: "PostalCode",
              id: "postalCode",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="State">State</label>
        <div>
          {renderPromptEditable({
            value: props.account.state,
            column: {
              Header: "State",
              id: "state",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Fan Verified States">Fan Verified States</label>
        <div>
          {renderPromptEditable({
            value: props.account.fanVerifStates,
            column: {
              Header: "fanVerifStates",
              id: "fanVerifStates",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Country">Country</label>
        <div>
          {renderPromptEditable({
            value: props.account.country,
            column: {
              Header: "Country",
              id: "country",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="Comment">Comment</label>
        <div>
          {renderPromptEditable({
            value: props.account.comment,
            column: {
              Header: "Comment",
              id: "comment",
            },
          })}
        </div>
      </li>
      <li>
        <label htmlFor="CreatedAt">Created At</label>
        <div>
          <span>{props.account.createdAt}</span>
        </div>
      </li>
    </ul>
  );
}
