import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import CreateAccount from "./components/CreateAccount";
import AccountAllInfos from "./components/AccountAllInfos";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatingAccount: false,
      updatingPassword: null,
      currentEmail: "",
      account: null,
      accountLists: [],
      currentList: { name: "" },
      filterCurrentList: "",
      oneTicketStatus: null,
      dsInfo: null,
    };
    this.setCurrentList = this.setCurrentList.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.addToList = this.addToList.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
    this.handleAccountUpdated = this.handleAccountUpdated.bind(this);
    this.handleCreateCreditCard = this.handleCreateCreditCard.bind(this);
    this.handleChangeCreditCard = this.handleChangeCreditCard.bind(this);
    this.handleRemoveCreditCard = this.handleRemoveCreditCard.bind(this);
    this.handleCardTypeChanged = this.handleCardTypeChanged.bind(this);
    this.handleOneTicketUpdated = this.handleOneTicketUpdated.bind(this);
    this.handleSentToDS = this.handleSentToDS.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  async fetchData() {
    this.setState({ loading: true });
    this.fetchAccountLists();
    this.fetchOneTicketStatus();
    this.fetchDS();
    const account = await this.fetchAccount();
    this.setState({ account, loading: false });
  }

  async fetchAccount() {
    let URL = `/api/accounts/${this.state.currentEmail}`;
    return await this.callApi(URL);
  }

  async fetchAccountLists() {
    let URL = "/api/accountLists";
    const accountLists = await this.callApi(URL);
    this.setState({ accountLists });
  }

  async fetchOneTicketStatus() {
    const oneTicketStatus = await this.callApi(
      `/api/oneticket/${this.state.currentEmail}`
    );
    this.setState({ oneTicketStatus });
  }

  async fetchDS() {
    let URL = `https://drop.plessinc.com/accounts/single/email/${this.state.currentEmail}`;
    const dsInfo = await this.callApi(URL);
    this.setState({ dsInfo });
  }

  setCurrentList(currentList) {
    if (currentList.id) {
      this.setState({ currentList });
    }
  }

  async addToList() {
    if (!this.state.currentList.id) {
      alert('Please select a list in the "Current List" field');
    } else {
      const association = await this.associate();
      const account = this.state.account;
      const index = account.account_list_accounts.push(association);
      account.account_list_accounts.splice(index, 1);
      this.setState({ account });
    }
  }

  async associate() {
    const result = await axios.post("/api/accountLists/associate", {
      accountListId: this.state.currentList.id,
      accountId: this.state.account.email,
    });
    return result.data;
  }

  removeFromList(listId) {
    const account = this.state.account;
    const index = account.account_list_accounts.findIndex(
      (val) => val.id === listId
    );
    account.account_list_accounts.splice(index, 1);
    this.setState({ account });
    this.deassociate(listId);
  }

  async deassociate(associationId) {
    await axios.post("/api/accountLists/deassociate", {
      id: associationId,
    });
  }

  handleUpdateAccount() {
    this.setState({ updatingAccount: true });
  }

  handleAccountUpdated() {
    this.fetchData();
    this.setState({ updatingAccount: false });
  }

  handleSentToDS() {
    this.fetchDS();
  }

  async handleOneTicketUpdated() {
    this.setState({
      updatingAccount: false,
    });
    await this.fetchData();
  }

  handleCreateAccount(email) {
    this.setState({
      account: {
        email,
        account_list_accounts: [],
        credit_cards: [],
        createdAt: `${moment.tz("UTC").format("YYYY-MM-DDTHH:mm:ss.000")}Z`,
      },
    });
  }

  async callApi(url) {
    try {
      const response = await fetch(url);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const data = await response.json();
        return data;
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  async handleCreateCreditCard() {
    const account = this.state.account;
    const creditCard = {
      accountId: account.email,
      nameOnCard: "",
      cardType: 1,
      last4: "",
      exp: "",
      cvv: "",
    };
    const id = await this.saveCreditCard(creditCard);
    creditCard.id = id;
    account.credit_cards.push(creditCard);
    this.setState({ account });
  }

  handleChangeCreditCard(cc) {
    this.saveCreditCard(cc);
  }

  async handleRemoveCreditCard(ccId) {
    if (window.confirm("Do you really want to delete that credit card?")) {
      const account = this.state.account;
      account.credit_cards = account.credit_cards.filter(
        (item) => item.id !== ccId
      );
      this.setState({ account });
      await axios.delete(`/api/creditCards/${ccId}`);
    }
  }

  async handleCardTypeChanged(target, original) {
    const account = this.state.account;
    const creditCard = account.credit_cards.find(
      (item) => item.id === original.id
    );
    creditCard.cardType = parseInt(target.value, 10);
    this.saveCreditCard(creditCard);
    this.setState({ account });
  }

  async saveCreditCard(creditCard) {
    const response = await axios.post("/api/creditcards/", creditCard);
    if (!creditCard.id) {
      return response.data.id;
    }
    return creditCard.id;
  }

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      this.fetchData();
    }
  };

  onChange(e) {
    this.setState({
      currentEmail: e.currentTarget.value,
    });
  }

  render() {
    const {
      account,
      accountLists,
      currentEmail,
      currentList,
      dsInfo,
      loading,
      oneTicketStatus,
      updatingAccount,
      updatingOneTicket,
    } = this.state;
    return (
      <div>
        <div>
          <fieldset>
            <legend>Find Details</legend>
            <div className="search-field">
              <input
                type="text"
                className={"input"}
                placeholder="Existing Email"
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                value={currentEmail}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Create Account</legend>
            <CreateAccount onCreate={this.handleCreateAccount} />
          </fieldset>
        </div>
        <AccountAllInfos
          account={account}
          accountLists={accountLists}
          currentList={currentList}
          dsInfo={dsInfo}
          loading={loading}
          oneTicketStatus={oneTicketStatus}
          updatingAccount={updatingAccount}
          updatingOneTicket={updatingOneTicket}
          onSetCurrentList={this.setCurrentList}
          onCreateCreditCard={this.handleCreateCreditCard}
          onChangeCreditCard={this.handleChangeCreditCard}
          onChangeCreditCardType={this.handleCardTypeChanged}
          onRemoveCreditCard={this.handleRemoveCreditCard}
          onAddToList={this.addToList}
          onRemoveFromList={this.removeFromList}
          onUpdateAccount={this.handleUpdateAccount}
          onOneTicketUpdated={this.handleOneTicketUpdated}
          onAccountUpdated={this.handleAccountUpdated}
          onSentToDS={this.handleSentToDS}
        />
      </div>
    );
  }
}

export default Account;
