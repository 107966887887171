const match = (skyboxRow, transactionToMatch) => {
  if (transactionToMatch) {
    const { skyboxPurchase, hasSkyboxPurchase, ...simpleTransaction } =
      transactionToMatch;
    const { transaction, hasTransaction, ...simpleSkyboxRow } = skyboxRow;
    const skyboxCopy = JSON.parse(JSON.stringify(simpleSkyboxRow));
    const transactionCopy = JSON.parse(JSON.stringify(simpleTransaction));
    transactionToMatch.skyboxPurchase = skyboxCopy;
    transactionToMatch.hasSkyboxPurchase = true;
    skyboxRow.transaction = transactionCopy;
    skyboxRow.hasTransaction = true;
  } else {
    skyboxRow.hasTransaction = false;
  }
};

const extractLast4 = (fullNumber) => {
  if (fullNumber.includes(",")) {
    return fullNumber.split(",").map((number) => extractLast4(number));
  }
  if (!fullNumber || fullNumber.length <= 4) {
    return fullNumber;
  }
  return fullNumber.substr(fullNumber.length - 4, 4);
};

const hasSameLast4 = (ccRow, skyboxRow) => {
  if (!ccRow.creditCard || ccRow.creditCard.length < 4) {
    return true;
  }
  const ccLast4 = extractLast4(ccRow.creditCard);
  const skyboxLast4 = extractLast4(skyboxRow.creditCard);
  return skyboxLast4.includes(ccLast4);
};

const filterByCost = (transactions, cost) => {
  const diff = Math.max(10, cost * 0.04);
  const lowCost = parseFloat(cost) - diff;
  const highCost = parseFloat(cost) + diff;
  const matchingCost = transactions.filter(
    (t) =>
      (t.totalCost > lowCost && t.totalCost < highCost) ||
      (t.totalCostWithFee > lowCost && t.totalCostWithFee < highCost)
  );
  if (matchingCost.length > 0) {
    return matchingCost[0];
  }
  return null;
};

const isMatch = (dsAccounts, row) => {
  const isStubhub = (row.site || "").toLowerCase().includes("stubhub");
  if (isStubhub) {
    return "True";
  }
  if (!row.hasSkyboxPurchase) {
    if (
      row.creditCard &&
      row.creditCard.length > 4 &&
      dsAccounts.includes(row.creditCard.substr(row.creditCard.length - 4))
    ) {
      return "DS";
    }
    return "False";
  }
  const sbCost = parseFloat(row.skyboxPurchase.cost);
  const lowCost = parseFloat(row.totalCost) - 0.05;
  const highCost = parseFloat(row.totalCost) + 0.05;
  if (sbCost > lowCost && sbCost < highCost) {
    return "True";
  }
  return "Partial";
};

const findColumnIndex = (columns, toFind, startingIndex = 0) => {
  for (let index = startingIndex; index < columns.length; index++) {
    const column = columns[index];
    if (column.trim().toLowerCase().includes(toFind.trim().toLowerCase())) {
      return index;
    }
  }
};

export {
  match,
  hasSameLast4,
  extractLast4,
  filterByCost,
  isMatch,
  findColumnIndex,
};
