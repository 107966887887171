module.exports = {
  Header: "Diff",
  id: "difference",
  width: 100,
  accessor: (row) =>
    row.skyboxPurchase &&
    (parseFloat(row.totalCost) - parseFloat(row.skyboxPurchase.cost)).toFixed(
      2
    ),
  getProps: (state, rowInfo) => {
    return { style: { textAlign: "right", paddingRight: "20px" } };
  },
  filterMethod: (filter, row, column) => {
    const diff = row.difference && parseFloat(row.difference);
    const symbol = filter.value.substr(0, 1);
    const amount = parseFloat(filter.value.substr(1));
    if (symbol === ">" && amount) {
      return diff > amount;
    }
    if (symbol === "<" && amount) {
      return diff < amount;
    }
    return diff && diff !== 0;
  },
};
