import React, { Component } from "react";

export default class ButtonToCheck extends Component {
  constructor() {
    super();
    this.state = {};
  }

  async handleClick(e) {
    if (this.props.onClick && !this.state.loading && !this.state.check) {
      await this.setState({ loading: true });
      await this.props.onClick(e);
      await this.changeToCheck();
      this.hideCheck();
    }
  }

  async changeToCheck() {
    this.setState({ loading: false, check: true });
  }

  hideCheck() {
    setTimeout(() => {
      this.setState({ check: false });
    }, 2000);
  }

  render() {
    let { onClick, text, ...props } = this.props;
    return (
      <button
        className={"btn btn-outline-primary"}
        {...props}
        onClick={this.handleClick.bind(this)}
      >
        {this.state.check ? (
          <span role="img" aria-label="checked">✔️</span>
        ) : (
          text
        )}
      </button>
    );
  }
}
