import React, { useState } from "react";
import axios from "axios";
import InputEnterAction from "../../../components/InputEnterAction";

export default function CreateAccount(props) {
  const [email, setEmail] = useState(() => {});

  async function handleCreate() {
    if (email && email.trim().length > 0) {
      await axios.post("/api/accounts", { email: email.trim() });
      if (props.onCreate) {
        props.onCreate(email.trim());
      }
      setEmail("");
    }
  }

  return (
    <div className="search-field">
      <InputEnterAction
        value={email}
        placeholder="New Account"
        onChange={(v) => setEmail(v.target.value)}
        onEnter={handleCreate}
      />
    </div>
  );
}
