import React from "react";
import ReactTable from "react-table";
import LoadingIcon from "../../components/LoadingIcon";
import PromptEditable from "../../components/PromptEditable";

export default function FanCodesTable(props) {
  function sortIndex(a, b) {
    if (a == null) {
      return 1;
    }
    if (b == null) {
      return -1;
    }
    return a > b ? 1 : -1;
  }

  function columnBackgroundColor(color) {
    return {
      style: {
        backgroundColor: color,
      },
    };
  }

  function renderPromptEditable(cellInfo) {
    return (
      <PromptEditable
        item={props.fanCodes[cellInfo.index]}
        cellInfo={cellInfo}
        itemChanged={props.onUpdateFanCode}
      />
    );
  }

  function promptDeleteCode(fanCode) {
    if (
      window.confirm(
        `Do you really want to delete the code "${fanCode.code}" ${
          fanCode.email ? `from account "${fanCode.email}" ` : ""
        } ?`
      )
    ) {
      props.onDeleteCode(fanCode);
    }
  }

  function renderDeleteButton(cellInfo) {
    return (
      <button onClick={() => promptDeleteCode(cellInfo.original)}>
        Delete
      </button>
    );
  }

  function renderProcessedCheckBox(cellInfo) {
    return (
      <input
        type="checkbox"
        name={cellInfo.column.Header}
        checked={cellInfo.value}
        onChange={() => props.onChangeProcessed(props.fanCodes[cellInfo.index])}
      />
    );
  }

  const columns = [
    {
      Header: "Account",
      columns: [
        {
          Header: "Index",
          accessor: "index",
          sortMethod: (a, b) => sortIndex(a, b),
          width: 50,
        },
        { Header: "Email", accessor: "email", width: 185 },
        { Header: "Password", accessor: "password" },
      ],
    },
    {
      Header: "Amex",
      columns: [
        {
          Header: "Last 4",
          accessor: "amex.last4",
          width: 60,
          getProps: () => columnBackgroundColor("#006fcf"),
        },
        {
          Header: "CVV",
          accessor: "amex.cvv",
          width: 60,
          getProps: () => columnBackgroundColor("#006fcf"),
        },
        {
          Header: "Exp",
          accessor: "amex.exp",
          width: 60,
          getProps: () => columnBackgroundColor("#006fcf"),
        },
      ],
    },
    {
      Header: "Com Data",
      columns: [
        {
          Header: "Last 4",
          accessor: "comData.last4",
          width: 60,
          getProps: () => columnBackgroundColor("#1191cd"),
        },
        {
          Header: "CVV",
          accessor: "comData.cvv",
          width: 60,
          getProps: () => columnBackgroundColor("#1191cd"),
        },
        {
          Header: "Exp",
          accessor: "comData.exp",
          width: 60,
          getProps: () => columnBackgroundColor("#1191cd"),
        },
      ],
    },
    {
      Header: "MasterCard",
      columns: [
        {
          Header: "Last 4",
          accessor: "mc.last4",
          width: 60,
          getProps: () => columnBackgroundColor("#ff5f00"),
        },
        {
          Header: "CVV",
          accessor: "mc.cvv",
          width: 60,
          getProps: () => columnBackgroundColor("#ff5f00"),
        },
        {
          Header: "Exp",
          accessor: "mc.exp",
          width: 60,
          getProps: () => columnBackgroundColor("#ff5f00"),
        },
      ],
    },
    {
      Header: "Code",
      columns: [
        { Header: "Event Name", accessor: "event", width: 125 },
        {
          Header: "Code",
          accessor: "code",
          width: 125,
          Cell: renderPromptEditable,
        },
        {
          Header: "Processed",
          accessor: "processed",
          Cell: renderProcessedCheckBox,
          style: { textAlign: "center" },
          width: 78,
        },
        {
          Header: "Comment",
          accessor: "comment",
          width: 450,
          Cell: renderPromptEditable,
        },
        {
          Header: "Delete",
          Cell: renderDeleteButton,
          width: 90,
        },
      ],
    },
  ];

  return (
    <ReactTable
      style={{ minHeight: "calc(100vh - 60px)" }}
      loading={props.loading}
      loadingText={<LoadingIcon />}
      filterable
      defaultFilterMethod={(filter, row) => {
        try {
          return new RegExp(filter.value, "i").test(row[filter.id]);
        } catch (err) {
          return true;
        }
      }}
      data={props.fanCodes || []}
      columns={columns}
      defaultSorted={[
        {
          id: "index",
          desc: false,
        },
      ]}
      defaultPageSize={100}
    />
  );
}
