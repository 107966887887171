import React from "react";
import ReactModal from "react-modal";
import ImportModalContent from "./ImportModalContent";

const ImportModal = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  ReactModal.setAppElement(this);
  return (
    <div style={{ ...props.style }}>
      <button className="btn" onClick={openModal}>
        Import
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ImportModalContent />
      </ReactModal>
    </div>
  );
};

const customStyles = {
  content: {
    top: "130px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0%)",
    maxHeight: "calc(100vh - 60px - 70px)",
  },
};

export default ImportModal;
