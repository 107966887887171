import React from "react";
import moment from "moment-timezone";
import ReactTable from "react-table";
import LoadingIcon from "../../../components/LoadingIcon";

class ModemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumberSum: 0,
      emailSum: 0,
      isOpenSum: 0,
      isListeningSum: 0,
      hasOperatorSum: 0
    };
  }

  getSums() {
    const data = this.props.data;
    if (!data) {
      return;
    }
    let phoneNumberSum = 0;
    let emailSum = 0;
    let isOpenSum = 0;
    let isListeningSum = 0;
    let hasOperatorSum = 0;
    for (let i = 0; i < data.length; i++) {
      const modem = data[i];
      if (modem.phoneNumber && modem.phoneNumber.toUpperCase() !== "UNKNOWN") {
        phoneNumberSum += 1;
      }
      if (modem.email) {
        emailSum += 1;
      }
      if (modem.isOpen) {
        isOpenSum += 1;
      }
      if (modem.isListening) {
        isListeningSum += 1;
      }
      if (modem.operator !== " 0") {
        hasOperatorSum += 1;
      }
    }
    return { phoneNumberSum, emailSum, isOpenSum, isListeningSum, hasOperatorSum };
  }

  getColumns({ phoneNumberSum, emailSum, isOpenSum, isListeningSum, hasOperatorSum }) {
    let columns = [
      {
        Header: "Index",
        id: "index",
        Cell: row => <div>{row.index + 1}</div>,
        width: 65,
        filterMethod: (filter, row) =>
          String(row.index + 1).toUpperCase() === filter.value.toUpperCase()
      },
      {
        Header: "Modem",
        id: "serverId",
        accessor: "name",
        width: 65,
        filterMethod: (filter, row) =>
          String(row[filter.id]).toUpperCase() === filter.value.toUpperCase()
      },
      {
        Header: "Distributick",
        id: "distributick",
        accessor: "distributick",
        width: 90,
        filterMethod: (filter, row) =>
          String(row[filter.id]).toUpperCase() === filter.value.toUpperCase()
      },
      {
        Header: "Phone Number",
        id: "phoneNumber",
        accessor: "phoneNumber",
        headerStyle: { textAlign: "center" },
        width: 110,
        Footer: () => `Total: ${phoneNumberSum}`
      },
      {
        Header: "Email",
        id: "email",
        accessor: "email",
        width: 250,
        Footer: () => `Total: ${emailSum}`
      },
      {
        Header: "COMPort",
        id: "port",
        accessor: "port",
        width: 75
      },
      {
        Header: "Is Open",
        id: "isOpen",
        accessor: "isOpen",
        width: 70,
        Footer: () => `Total: ${isOpenSum}`
      },
      {
        Header: "Is Listening",
        id: "isListening",
        accessor: "isListening",
        width: 90,
        Footer: () => `Total: ${isListeningSum}`
      },
      {
        Header: "Operator",
        id: "operator",
        accessor: "operator",
        width: 90,
        Footer: () => `Total: ${hasOperatorSum}`
      },
      {
        Header: "Last updated",
        id: "lastUpdated",
        accessor: "lastUpdated",
        Cell: row => (
          <span>
            {moment
              .tz(row.value, "UTC")
              .tz("America/Toronto")
              .format("YYYY-MM-DD HH:mm:ss")}
          </span>
        ),
        width: 150
      }
    ];
    return columns;
  }

  render() {
    const sums = this.getSums();
    const columns = this.getColumns(sums);
    const rows = this.props.data;
    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px)" }}
        loading={this.props.loading}
        loadingText={<LoadingIcon />}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
        data={rows}
        columns={columns}
        defaultSorted={[
          {
            id: "dateTimeSent",
            desc: true
          }
        ]}
        defaultPageSize={100}
      />
    );
  }
}

export default ModemList;
