import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { CSVLink } from "react-csv/lib";
import CreateAccountListField from "../../components/Accounts/CreateAccountListField";
import AccountListTable from "./AccountListTable";

export default function AccountList(props) {
  const [lists, setLists] = useState([]);
  const [fileAllData, setFileAllData] = useState([]);
  const [filePhoneData, setFilePhoneData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [accountListName, setAccountListName] = useState("");
  const [ccTypeName, setCcTypeName] = useState("");

  async function refreshLists() {
    let URL = "/api/accountLists/subscriptions";
    const response = await axios.get(URL);
    setLists(response.data);
  }

  function handleUpdateAccountLists() {
    setLists([...lists]);
  }

  function generateDistributikFile(accountList, ccType) {
    const ccNameType = ccNameByType(ccType, true);
    setCcTypeName(ccNameType);
    setFileName(
      `${accountList.name}_${ccNameType}_${moment().format("YYYY-MM-DD")}`
    );
    const data = accountList.account_list_accounts.filter(a => {
      const account = a.accounts[0];
      return findCC(account, ccType);
    });
    const phoneList = data
      .map(a => {
        const account = a.accounts[0];
        return [`${account.phone},`];
      })
      .join("\n");
    const allData = data
      .map(a => {
        const account = a.accounts[0];
        const cc = findCC(account, ccType);
        const accountCCTypeName = ccNameByType(cc.cardType, false);
        const country =
          account.country &&
          !["USA", "US", "UNITED STATES"].includes(
            account.country.toUpperCase()
          )
            ? account.country.toUpperCase().substring(0, 2)
            : "";
        const name = (account.name || "").replace(/\s/g, "") || "";
        return [
          `${account.index ||
            ""}${country}${accountCCTypeName}${name},${account.email ||
            ""},${account.password || ""},${cc.last4 || ""},${cc.cvv ||
            ""},,${account.phone || ""}`
        ];
      })
      .join("\n");

    setAccountListName(accountList.name);
    setFileAllData(allData);
    setFilePhoneData(phoneList);
  }

  function generateFanVerifFile(accountList) {
    setCcTypeName("fan verif");
    const fanVerifData = accountList.account_list_accounts
      .map(a => {
        const account = a.accounts[0];
        const name = (account.name || "").replace(/\s/g, "").toLowerCase();
        const phoneNumber = (account.phone || "").substring(1, 11) || "";
        return [
          `${name},${account.email || ""},${account.password ||
            ""},+1,${phoneNumber},${account.postalCode ||
            ""},${account.country || ""},${account.fanVerifStates || ""}`
        ];
      })
      .join("\n");

    setAccountListName(accountList.name);
    setFileAllData(fanVerifData);
    setFilePhoneData([]);
    setFileName(
      `${accountList.name}_FAN_VERIF_${moment().format("YYYY-MM-DD")}`
    );
  }

  function ccNameByType(type, hasAll) {
    return type === 1
      ? "AMEX"
      : type === 2
      ? "COM"
      : type === 3
      ? "MC"
      : hasAll
      ? "All"
      : "";
  }

  function findCC(account, ccType) {
    let cc;
    if (ccType === 0) {
      cc = account.credit_cards.find(c => !c.dateDeleted);
    } else {
      cc = account.credit_cards.find(
        c => c.cardType === ccType && !c.dateDeleted
      );
    }
    return cc;
  }

  useEffect(() => {
    refreshLists();
  }, []);

  return (
    <div>
      <div>
        <CreateAccountListField onAccountListCreated={() => refreshLists()} />
        {fileName && (
          <CSVLink data={fileAllData} filename={`${fileName}.txt`}>
            <span>{` Download ${accountListName} ${ccTypeName} `}</span>
          </CSVLink>
        )}
        {fileName && filePhoneData.length > 0 && (
          <CSVLink
            data={filePhoneData}
            filename={`${fileName}_phone.txt`}
            style={{ marginLeft: "15px" }}
          >
            <span>{` Download ${accountListName} Phones `}</span>
          </CSVLink>
        )}
      </div>
      <div>
        <AccountListTable
          user={props.user}
          accountLists={lists}
          onUpdateAccountLists={handleUpdateAccountLists}
          generateFanVerifFile={generateFanVerifFile}
          generateDistributikFile={generateDistributikFile}
          onSubscribe={refreshLists}
          onUnsubscribe={refreshLists}
        />
      </div>
    </div>
  );
}
