import React, { Component } from "react";
import ReactTable from "react-table";
import SubTable from "../../../components/SubTable";
import LoadingIcon from "../../../components/LoadingIcon";
import { isMatch } from "../logic/transactionMatcher";
import diffColumn from "./columns/diffColumn";

class BMOTable extends Component {
  calculateSumCCCosts = (data) => {
    if (data) {
      return `Total : ${parseFloat(
        data.reduce(
          (acc, current) => acc + parseFloat(current._original.totalCost || 0),
          0
        )
      ).toFixed(2)}`;
    }
    return "Total : 0.00";
  };

  getTrProps = (state, rowInfo, instance) => {
    let background = "#FF8282";
    if (rowInfo) {
      const isMatching = isMatch(this.props.dsAccounts, rowInfo.original);
      if (isMatching === "True") {
        background = "lightGreen";
      } else if (isMatching === "False") {
        background = "#FF8282";
      } else if (isMatching === "DS") {
        background = "#FFAF60";
      } else {
        background = "lightYellow";
      }
      return {
        style: {
          background,
          color: "white",
        },
      };
    }
    return {};
  };

  getColumns = () => {
    return [
      {
        Header: "Matched",
        id: "hasSkyboxPurchase",
        accessor: (row) => isMatch(this.props.dsAccounts, row),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">All</option>
            <option value="True">True</option>
            <option value="False">False</option>
            <option value="Partial">Partial</option>
            <option value="DS">DS</option>
          </select>
        ),
        filterAll: true,
        filterMethod: (filter, toFilter, column) => {
          const filtered = toFilter.filter((item) => {
            return (item[filter.id] || "")
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          });
          return filtered;
        },
        width: 65,
      },
      {
        Header: "Charge Date",
        id: "purchaseDate",
        accessor: (row) => row.purchaseDate,
        filterAll: true,
        width: 91,
      },
      {
        Header: "Credit Card",
        id: "creditCard",
        width: 100,
        accessor: (row) => {
          const url =
            `https://skybox.vividseats.com/reports/12?` +
            `parameters=%7B%22id%22:12,` +
            `%22draft%22:false,` +
            `%22inventoryTag%22:%22mc_${row.creditCard}%22,` +
            `%22purchaseDate%22:%22customRange%22,` +
            `%22purchaseDateFrom%22:%22${this.props.purchaseDateFrom}%22,` +
            `%22purchaseDateTo%22:%22${this.props.purchaseDateTo}%22` +
            `%7D`;
          return (
            <div>
              {row.creditCard}
              <a href={url} target="_blank">
                <img
                  src="/img/open-in-browser.png"
                  alt="Open in other tab"
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
          );
        },
        filterMethod: (filter, row, column) => {
          const last4 = row._original.creditCard || "";
          return last4.toLowerCase().includes(filter.value.toLowerCase());
        },
      },
      {
        Header: "Supplier",
        id: "site",
        accessor: "site",
        filterAll: true,
        width: 320,
      },
      {
        Header: "Real Confirmation #",
        id: "realConfirmationNumber",
        width: 150,
        accessor: (row) => {
          if (!!row.skyboxPurchase) {
            const sbConfirmationNumber = row.skyboxPurchase.confirmationNumber;
            const url = `https://skybox.vividseats.com/reports/12?parameters=%7B%22id%22:12,%22draft%22:false,%22internalNotes%22:%22${sbConfirmationNumber}%22%7D`;
            return (
              <div>
                {sbConfirmationNumber}
                <a href={url} target="_blank">
                  <img
                    src="/img/open-in-browser.png"
                    alt="Open in other tab"
                    style={{ width: "25px", height: "25px" }}
                  />
                </a>
              </div>
            );
          } else {
            return "";
          }
        },
        filterMethod: (filter, row, column) => {
          return (
            row._original.skyboxPurchase
              ? row._original.skyboxPurchase.confirmationNumber || ""
              : ""
          )
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },
      },
      {
        Header: "Cost",
        id: "cost",
        accessor: (row) => parseFloat(row.totalCost).toFixed(2),
        getProps: (state, rowInfo) => {
          return { style: { textAlign: "right", paddingRight: "20px" } };
        },
        Footer: (columnProps) => {
          return (
            <span>
              {columnProps.data.length > 0
                ? this.calculateSumCCCosts(columnProps.data)
                : 0}
            </span>
          );
        },
        filterAll: true,
        width: 120,
      },
      diffColumn,
    ];
  };

  render() {
    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px - 114px - 46px)" }}
        filterable
        loading={this.props.loading}
        data={this.props.data || []}
        loadingText={<LoadingIcon />}
        getTrProps={this.getTrProps}
        defaultFilterMethod={(filter, toFilter, column) => {
          const filtered = toFilter.filter((item) => {
            return (item._original[filter.id] || "")
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          });
          return filtered;
        }}
        SubComponent={(row) => {
          if (!!row.original.skyboxPurchase) {
            return <SubTable data={row.original.skyboxPurchase} />;
          }
          return null;
        }}
        columns={this.getColumns()}
        defaultSorted={[
          {
            id: "purchaseDate",
            desc: true,
          },
        ]}
        defaultPageSize={100}
      />
    );
  }
}

export default BMOTable;
