import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../scss/app.scss";
import Router from "./Router";
import LoadingIcon from "../components/LoadingIcon";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
      user: null
    };
  }

  componentDidMount() {
    this.getUser();
  }

  async getUser() {
    const response = await fetch("/auth/user", {
      method: "GET"
    });
    if (response.status === 200) {
      try {
        let { user } = await response.json();
        this.setState({
          loading: false,
          loaded: true,
          user
        });
      } catch (e) {
        console.error(e);
      }
    } else if (response.status === 500) {
      this.getUser();
    } else {
      this.setState({
        loading: false,
        loaded: true
      });
    }
  }

  render() {
    const loaded = this.state.loaded;
    return (
      <div>
        {loaded ? (
          <div>
            <Router user={this.state.user} />
          </div>
        ) : (
          <div className={`load${this.state.loading ? "" : " loaded"}`}>
            <div className="load__icon-wrap">
              <LoadingIcon />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
