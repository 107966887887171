import React from "react";
import ReactTable from "react-table";

export default function CreditCardTable(props) {
  function renderEditable(cellInfo) {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          props.creditCards[cellInfo.index][cellInfo.column.id] =
            e.currentTarget.textContent;
          props.onChange(props.creditCards[cellInfo.index]);
        }}
        dangerouslySetInnerHTML={{
          __html: props.creditCards[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  }

  const columns = [
    {
      Header: "id",
      accessor: "id",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      show: false,
    },
    {
      Header: "Remove",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return <button onClick={() => props.onRemove(original.id)}>-</button>;
      },
    },
    {
      Header: "Card Type",
      accessor: "cardType",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <select
            value={original.cardType}
            onChange={(e) => props.onChangeCardType(e.target, original)}
          >
            <option value="1">Amex</option>
            <option value="2">ComData</option>
            <option value="3">MC</option>
          </select>
        );
      },
    },
    {
      Header: "Name on Card",
      accessor: "nameOnCard",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: renderEditable,
    },
    {
      Header: "Last 4",
      accessor: "last4",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: renderEditable,
    },
    {
      Header: "CVV",
      accessor: "cvv",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: renderEditable,
    },
    {
      Header: "Exp",
      accessor: "exp",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      Cell: renderEditable,
    },
  ];

  return (
    <div>
      <ReactTable
        style={{ height: (props.creditCards || []).length * 36 + 25 }}
        data={props.creditCards || []}
        columns={columns}
        pageSize={(props.creditCards || []).length}
        showPagination={false}
      />
      <button
        onClick={props.onCreate}
        className="btn btn-outline-primary"
        style={{ padding: "-13.625rem 0.75rem" }}
        role="img"
      >
        ➕
      </button>
    </div>
  );
}
