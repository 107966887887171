import React from "react";
import moment from "moment-timezone";
import { Button } from "reactstrap";
import Excel from "../../../components/Table/Excel";

class SmsExportButton extends React.Component {
  formatData() {
    if (this.props.data) {
      return this.props.data.map((sms) => {
        return {
          phoneNumber: sms.to,
          email: sms.email || "",
          message: sms.message,
          distributick: sms.distributick,
          dateTimeSent: moment(sms.dateTimeSent).format("YYYY-MM-DDTHH:mm:ss"),
        };
      });
    }
    return [];
  }

  render() {
    const excelData = this.formatData();
    return (
      <Excel
        filename={`sms_${moment().format("YYYY-MM-DD")}.xlsx`}
        onDownload={(callback) => {
          callback();
        }}
        element={
          <button className={"btn btn-outline-primary"}>
            <i className="glyphicon glyphicon-save" />
            Export
          </button>
        }
      >
        <Excel.Sheet data={excelData} name="Sheet A">
          <Excel.Column label="Distributick Index" value="distributick" />
          <Excel.Column label="Phone Number" value="phoneNumber" />
          <Excel.Column label="Email" value="email" />
          <Excel.Column label="Datetime Sent" value="dateTimeSent" />
          <Excel.Column label="Message" value="message" />
        </Excel.Sheet>
      </Excel>
    );
  }
}

export default SmsExportButton;
