import React, { Component } from "react";
import SmsList from "./components/SmsList";
import SmsExportButton from "./components/SmsExportButton";
import FilterList from "../../components/FilterList";

class SmsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sms: [],
      accounts: [],
      fields: [
        { value: "dateTimeSent", label: "Date Received" },
        { value: "distributick", label: "Distributick" },
        { value: "email", label: "Email" },
        { value: "from", label: "From" },
        { value: "message", label: "Message" },
        { value: "name", label: "Modem" },
        { value: "to", label: "Phone Number (to)" }
      ]
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
    this.startListening();
  }

  componentWillUnmount() {
    this.stopListening();
  }

  stopListening() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  async startListening() {
    this.listen();
    const interval = setInterval(() => {
      this.listen();
    }, 3000);
    this.setState({ interval });
  }

  async getAccounts() {
    this.setState({ loading: true });
    const URL = `/api/accounts`;
    const result = await this.callApi(URL);
    if (result) {
      const sms = this.mergeAccountToSms(this.state.sms, result);
      this.setState({ loading: false, sms, accounts: result });
    } else {
      this.setState({ loading: false });
    }
  }

  async listen() {
    this.setState({ loading: true });
    const URL = `${process.env.REACT_APP_SMSGATE_API_URL}/api/sms/all`;
    const result = await this.callApi(URL);
    if (result) {
      const sms = this.mergeAccountToSms(result, this.state.accounts);
      this.setState({ loading: false, sms });
    } else {
      this.setState({ loading: false });
    }
  }

  mergeAccountToSms(smsList, accounts) {
    if (!smsList) {
      return [];
    }
    return smsList.map(smsData => {
      if (smsData.to) {
        const account = accounts.find(a => a.phone === smsData.to);
        if (account) {
          return {
            ...smsData,
            distributick: account.index,
            email: account.email
          };
        }
      }
      return smsData;
    });
  }

  async callApi(url) {
    try {
      const response = await fetch(url);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        return await response.json();
      }
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
  }

  handleFilter(filtered) {
    this.setState({ filteredSms: filtered });
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "70%" }}>
          <SmsList data={this.state.filteredSms || this.state.sms} />
        </div>
        <div style={{ width: "30%" }}>
          <FilterList
            hasCodeFilter={true}
            filterName="sms"
            fields={this.state.fields}
            data={this.state.sms}
            filterChanged={this.handleFilter}
          />
          <SmsExportButton data={this.state.filteredSms || this.state.sms} />
        </div>
      </div>
    );
  }
}

export default SmsPage;
