import React, { Component } from "react";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import { withRouter } from "react-router-dom";

class Layout extends Component {
  render() {
    return (
      <div>
        <Topbar user={this.props.user} />
        <Sidebar user={this.props.user} />
      </div>
    );
  }
}

export default withRouter(Layout);
