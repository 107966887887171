import React from "react";
import ReactTable from "react-table";
import LoadingIcon from "../../../components/LoadingIcon";
import Axios from "axios";

class UserList extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.renderEditable = this.renderEditable.bind(this);
  }

  handleFilterChange(filter) {
    this.setState({ usersFilter: filter });
  }

  renderEditable(cellInfo) {
    let saveUser = e => {
      const users = [...this.props.users];
      users[cellInfo.index][cellInfo.column.id] = e.target.value;
      this.props.onChangeUser(users);
      this.sendUser(users[cellInfo.index]);
    };
    const options = this.getOptions();
    return (
      <select
        className={"input"}
        value={this.props.users[cellInfo.index][cellInfo.column.id]}
        style={{ backgroundColor: "#fafafa" }}
        onChange={e => {
          saveUser(e);
        }}
      >
        <option value="">Show All</option>
        {options.map(e => {
          return (
            e.value && (
              <option key={e.value} value={e.value}>
                {e.value}
              </option>
            )
          );
        })}
      </select>
    );
  }

  getColumns() {
    let columns = [
      { Header: "Email", accessor: "email", width: 250 },
      {
        Header: "Role",
        accessor: "role",
        width: 185,
        Cell: this.renderEditable,
        filterMethod: (filter, row) => {
          if (filter.value === "") {
            return true;
          }
          return (
            filter.value == null ||
            !filter.value ||
            (row._original && row.role === filter.value)
          );
        },
        Filter: ({ filter, onChange }) => {
          const options = this.getOptions();
          return (
            <select
              onChange={event => {
                this.handleFilterChange.bind(this);
                onChange(event.target.value);
              }}
            >
              <option value="">Show All</option>
              {options.map(e => {
                return (
                  e.value && (
                    <option key={e.value} value={e.value} selected={filter && filter.value === e.value }>
                      {e.value}
                    </option>
                  )
                );
              })}
            </select>
          );
        }
      }
    ];
    return columns;
  }

  getOptions() {
    return Object.keys(this.props.roles).map(role => {
      return { value: this.props.roles[role], label: this.props.roles[role] };
    });
  }

  async sendUser(user) {
    Axios.post("/api/users/", user);
  }

  render() {
    let columns = this.getColumns();

    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px - 98px)" }}
        loading={this.state.loading}
        loadingText={<LoadingIcon />}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]).indexOf(filter.value) > -1
        }
        data={this.props.users || []}
        columns={columns}
      />
    );
  }
}

export default UserList;
