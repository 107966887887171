import React, { Component } from "react";
import SidebarLink from "./SidebarLink";
import UserRoles from "../../../components/UserRoles";

class SidebarContent extends Component {
  hideSidebar = () => {
    this.props.onClick();
  };

  hasAccessToUsers() {
    return this.isInArray([UserRoles.Dev]);
  }

  hasAccessToSms() {
    return this.isInArray([
      UserRoles.Dev,
      UserRoles.Buyer,
      UserRoles.Admin,
      UserRoles.AccountManagement,
    ]);
  }

  hasAccessToModems() {
    return this.isInArray([
      UserRoles.Dev,
      UserRoles.Buyer,
      UserRoles.Admin,
      UserRoles.AccountManagement,
    ]);
  }

  hasAccessToDistributick() {
    return this.isInArray([UserRoles.Dev, UserRoles.AccountManagement]);
  }

  hasAccessToCoachella() {
    return this.isInArray([UserRoles.Dev, UserRoles.Accounting]);
  }

  hasAccessToAccounting() {
    return this.isInArray([UserRoles.Dev, UserRoles.Accounting]);
  }

  isInArray(roles) {
    return roles.includes(this.props.user.role);
  }

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Account" icon="user" route="/pages/account" />
          <SidebarLink title="Accounts" icon="users" route="/pages/accounts" />
          <SidebarLink
            title="Account Lists"
            icon="book"
            route="/pages/accountLists"
          />
          <SidebarLink
            title="Limits"
            icon="heart-pulse"
            route="/pages/limits"
          />
          <SidebarLink title="Fan Codes" icon="hand" route="/pages/fanCodes" />
          {this.hasAccessToDistributick() && (
            <SidebarLink
              title="Distributick"
              icon="neutral"
              route="/pages/distributick"
            />
          )}
          {this.hasAccessToCoachella() && (
            <SidebarLink
              title="Coachella"
              icon="music-note"
              route="/pages/coachella"
            />
          )}
          {this.hasAccessToUsers() && (
            <SidebarLink title="Users" icon="cog" route="/pages/users" />
          )}
          {this.hasAccessToSms() && (
            <SidebarLink title="Sms" icon="envelope" route="/pages/sms" />
          )}
          {this.hasAccessToModems() && (
            <SidebarLink title="Modems" icon="phone" route="/pages/modems" />
          )}
          {this.hasAccessToAccounting() && (
            <SidebarLink
              title="Accounting"
              icon="unlink"
              route="/pages/accounting"
            />
          )}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
