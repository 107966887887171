import React, { useState } from "react";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";

export default (props) => {
  const onCheckChange = (e) => {
    props.onChange(e.target.checked);
  };

  return (
    <label
      className={`checkbox-btn${props.disabled ? " disabled" : ""}${
        props.class ? ` checkbox-btn--${props.class}` : ""
      }`}
    >
      <input
        className="checkbox-btn__checkbox"
        type="checkbox"
        name={props.name}
        onChange={onCheckChange}
        checked={props.value}
        disabled={props.disabled}
      />
      <span
        className="checkbox-btn__checkbox-custom"
        style={
          props.color
            ? { background: props.color, borderColor: props.color }
            : {}
        }
      >
        <CheckIcon />
      </span>
      {props.class === "button" ? (
        <span className="checkbox-btn__label-svg">
          <CheckIcon className="checkbox-btn__label-check" />
          <CloseIcon className="checkbox-btn__label-uncheck" />
        </span>
      ) : (
        ""
      )}
      <span className="checkbox-btn__label">{props.label}</span>
    </label>
  );
};
