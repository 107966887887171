import React, { Component } from "react";
import moment from "moment-timezone";

class EventHeader extends Component {
  render() {
    return (
      <div>
        <span>{this.props.event.eventName}</span>
        <br />
        <span>{moment(this.props.event.eventDate).format("YYYY-MM-DD")}</span>
        <br />
        <span>{moment(this.props.event.eventDate).format("HH:mm")}</span>
        <br />
        <div className="venue-name">{this.props.event.venue}</div>
      </div>
    );
  }
}

export default EventHeader;
