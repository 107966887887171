import React, { Component } from "react";

class ReactTableFilter extends Component {
  constructor() {
    super();
    this.state = { items: [], selected: {} };
  }

  componentDidMount() {
    if (!!this.props.apiUrl) {
      this.loadDataFromApi();
    } else {
      this.setState({ items: this.props.filters });
    }
  }

  loadDataFromApi = async () => {
    try {
      const response = await fetch(`/api${this.props.apiUrl}`);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        let items = await response.json();
        this.setState({ items });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleChange(event) {
    this.setState({ selected: event });
    this.props.onFilterChange(event);
  }

  generateOptions() {
    let options = this.state.items;
    if (this.props.filterOptions && options.length > 0) {
      options = this.props.filterOptions(options);
    }
    options = options
      .reduce((acc, item) => {
        let value = item;
        let label = item;
        if (!!this.props.optionValueProperty) {
          value = item[this.props.optionValueProperty];
        }
        if (!!this.props.optionLabelProperty) {
          label = item[this.props.optionLabelProperty];
        }
        if (!acc.some((a) => a.value === value)) {
          acc.push({ value, label });
        }
        return acc;
      }, [])
      .sort((a, b) =>
        a.label
          ? (a.label || "").toUpperCase() - (b.label || "").toUpperCase()
          : a.value - b.value
      );
    return options;
  }

  render() {
    const options = this.generateOptions();
    return (
      <select
        onChange={this.handleChange.bind(this)}
        value={!!this.state.selected ? this.state.selected.value : "all"}
      >
        <option value="all">All</option>
        {options.map((e) => {
          return (
            e.value && (
              <option key={e.value} value={e.value}>
                {e.label}
              </option>
            )
          );
        })}
      </select>
    );
  }
}
export default ReactTableFilter;
