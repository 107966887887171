import React, { Component } from "react";
import Select from "react-select";
import InputEnterAction from "./InputEnterAction";

class ModemFilter extends Component {
  constructor(props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleOperationChange = this.handleOperationChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  getOperations() {
    return [
      { label: "Contains", value: "%%" },
      { label: "Don't Contains", value: "<>" },
      { label: "Equals", value: "===" },
      { label: "Start With", value: "*%" },
      { label: "End With", value: "%*" },
      { label: "Length", value: "len" }
    ];
  }

  handleFieldChange(e) {
    const filter = this.props.filter;
    filter.field = e;
    this.props.onChange(filter, this.props.index);
  }

  handleOperationChange(e) {
    const filter = this.props.filter;
    filter.operation = e;
    this.props.onChange(filter, this.props.index);
  }

  handleValueChange(e) {
    const filter = this.props.filter;
    filter.value = e.target.value;
    this.props.onChange(filter, this.props.index);
  }

  handleEnter() {
    this.props.onEnter(this.props.filter, this.props.index);
  }

  handleRemove() {
    this.props.onRemove(this.props.filter, this.props.index);
  }

  render() {
    const operations = this.getOperations();
    const { filter, fields } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <button onClick={this.handleRemove} style={{ width: "50px" }}>
          -
        </button>
        <div style={{ width: "133px" }}>
          <Select
            options={fields}
            value={filter.field}
            onChange={this.handleFieldChange}
          />
        </div>
        <div style={{ width: "133px" }}>
          <Select
            options={operations}
            value={filter.operation}
            onChange={this.handleOperationChange}
          />
        </div>
        <div style={{ width: "133px" }}>
          <InputEnterAction
            style={{ width: "133px" }}
            value={filter.value}
            onChange={this.handleValueChange}
            onEnter={this.handleEnter}
          />
        </div>
      </div>
    );
  }
}
export default ModemFilter;
