import React, { useState, useEffect } from "react";
import axios from "axios";
import SkyBoxTable from "./SkyboxTable";
import AmexTable from "./AmexTable";
import ComdataTable from "./ComdataTable";
import FlipTable from "./FlipTable";
import ExportButton from "./ExportButton";
import BMOTable from "./BMOTable";

export default function (data) {
  const isLoading = data.data.loading;
  const amexData = [...data.data.amexUsdData, ...data.data.amexCadData];
  const comdataData = [
    ...data.data.comdataUsdData,
    ...data.data.comdataCadData,
  ];
  const bmoData = [...data.data.bmoUsdData, ...data.data.bmoCadData];
  const [dsAccounts, setDsAccounts] = useState([]);

  const [content, setContent] = useState("amex");

  useEffect(() => {
    fetchDSAccounts();
  }, []);

  const fetchDSAccounts = async () => {
    const response = await axios.get("https://drop.plessinc.com/accounts/all");
    setDsAccounts(
      response.data.reduce((acc, current) => {
        if (current.is_ds) {
          acc.push(current.cc_last4);
        }
        return acc;
      }, [])
    );
  };

  const handleClick = (e) => {
    setContent(e);
  };

  const isActiveTab = (tabName) => {
    return content === tabName;
  };

  const Tab = (props) => {
    const baseTabStyle = {
      padding: "10px 15px",
      border: "1px solid black",
      cursor: "pointer",
    };
    const activeTabStyle = { background: "#fe5029", ...baseTabStyle };
    const inactiveTabStyle = { background: "#f3f5f8", ...baseTabStyle };
    return (
      <div
        onClick={handleClick.bind(this, props.name)}
        style={isActiveTab(props.name) ? activeTabStyle : inactiveTabStyle}
      >
        {props.title}
      </div>
    );
  };

  const Content = () => {
    return (
      <div>
        {content === "amex" ? (
          <AmexTable
            data={amexData}
            loading={isLoading}
            dsAccounts={dsAccounts}
            purchaseDateFrom={data.purchaseDateFrom}
            purchaseDateTo={data.purchaseDateTo}
          />
        ) : content === "comdata" ? (
          <ComdataTable
            data={comdataData}
            loading={isLoading}
            dsAccounts={dsAccounts}
            purchaseDateFrom={data.purchaseDateFrom}
            purchaseDateTo={data.purchaseDateTo}
          />
        ) : content === "bmo" ? (
          <BMOTable
            data={bmoData}
            loading={isLoading}
            dsAccounts={dsAccounts}
            purchaseDateFrom={data.purchaseDateFrom}
            purchaseDateTo={data.purchaseDateTo}
          />
        ) : content === "skybox" ? (
          <SkyBoxTable
            data={data.data.skyboxData}
            loading={isLoading}
            dsAccounts={dsAccounts}
            purchaseDateFrom={data.purchaseDateFrom}
            purchaseDateTo={data.purchaseDateTo}
          />
        ) : content === "flip" ? (
          <FlipTable
            data={data.data.flipData}
            loading={isLoading}
            dsAccounts={dsAccounts}
            purchaseDateFrom={data.purchaseDateFrom}
            purchaseDateTo={data.purchaseDateTo}
          />
        ) : (
          <span>Empty</span>
        )}
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Tab name="amex" title="Amex" />
        <Tab name="comdata" title="Comdata" />
        <Tab name="bmo" title="BMO" />
        <Tab name="skybox" title="Skybox" />
        <Tab name="flip" title="Flip" />
        <ExportButton
          skyboxData={data.data.skyboxData}
          flipData={data.data.flipData}
          amexData={amexData}
          comdataData={comdataData}
          bmoData={bmoData}
          dsAccounts={dsAccounts}
        />
      </div>
      <Content />
    </div>
  );
}
