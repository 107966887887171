import React, {Component} from 'react';

class LogInForm extends Component {  
  render() {
    const {handleSubmit} = this.props;
    let buttonStyle= {marginRight:"auto", marginLeft: "auto"};
    
    return (
      <form className='form' onSubmit={handleSubmit}>
        <a className='btn btn-outline-primary account__btn account__btn--small' style={buttonStyle}
           href={`${process.env.REACT_APP_API_URL}/auth/google`}>
            <img alt="Sign in with Google" style={{width: 20}} src="/img/google_logo.jpg"/> Sign in with Google
        </a>
      </form>
    )
  }
}

export default LogInForm;
