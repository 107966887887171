import React, { useState } from "react";
import AutoCompleteField from "../../../components/AutoCompleteField";

export default function (props) {
  return (
    <div style={{ display: "flex" }}>
      <AutoCompleteField
        key={"autocomplete"}
        placeholder="Current List"
        onSelectionChange={props.onSetCurrentList}
        suggestions={props.accountLists}
        value={props.currentList}
      />
      <button
        onClick={props.onAddToList}
        className="btn btn-outline-primary"
        style={{ marginBottom: "5px", padding: "0.375rem 0.75rem" }}
        role="img"
      >
        ➕
      </button>
    </div>
  );
}
