import React from "react";

export default (props) => {
  return (
    <div>
      <h3 style={{ color: "red" }}>Errors</h3>
      {props.columns.map((c) => {
        if (c.error) {
          return (
            <div key={`${c.indexColumn} - ${c.columnName}`}>{c.error}</div>
          );
        }
      })}
    </div>
  );
};
