import React from "react";
import ReactTable from "react-table";
import LoadingIcon from "../../components/LoadingIcon";
import PromptEditable from "../../components/PromptEditable";
import axios from "axios";

export default function AccountListTable(props) {
  async function unsubscribe(list) {
    await axios.post("/api/accountLists/unsubscribe", {
      accountListId: list.id,
    });
    if (props.onUnsubscribe) {
      props.onUnsubscribe(list);
    }
  }

  async function itemChanged(item) {
    props.onUpdateAccountLists(item);
    await axios.post("/api/accountLists/", item);
  }

  function renderPromptEditable(cellInfo) {
    return (
      <PromptEditable
        item={props.accountLists[cellInfo.index]}
        cellInfo={cellInfo}
        itemChanged={itemChanged}
      />
    );
  }

  async function subscribe(list) {
    await axios.post("/api/accountLists/subscribe", {
      accountListId: list.id,
    });
    if (props.onSubscribe) {
      props.onSubscribe(list);
    }
  }

  function getColumns() {
    let columns = [
      {
        Header: "Id",
        accessor: "id",
        headerStyle: { textAlign: "center" },
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: renderPromptEditable,
        width: 185,
      },
      {
        Header: (
          <div>
            Linked
            <br />
            Account
          </div>
        ),
        id: "linkedAccount",
        accessor: "account_list_accounts.length",
        headerStyle: { textAlign: "center" },
        width: 75,
        Cell: ({ original }) => original.account_list_accounts.length,
      },
      {
        Header: (
          <div>
            Generate
            <br />D File
          </div>
        ),
        id: "subscribe",
        width: 100,
        headerStyle: { textAlign: "center" },
        Cell: ({ original }) => (
          <div>
            <div>
              <button
                onClick={() => props.generateDistributikFile(original, 0)}
              >
                All
              </button>
            </div>
            <div>
              <button
                onClick={() => props.generateDistributikFile(original, 1)}
              >
                Amex
              </button>
            </div>
            <div>
              <button
                onClick={() => props.generateDistributikFile(original, 2)}
              >
                ComData
              </button>
            </div>
            <div>
              <button
                onClick={() => props.generateDistributikFile(original, 3)}
              >
                MC
              </button>
            </div>
            <div>
              <button onClick={() => props.generateFanVerifFile(original)}>
                Fan Verif
              </button>
            </div>
          </div>
        ),
      },
      {
        Header: "Subscribe",
        id: "subscribe",
        width: 100,
        headerStyle: { textAlign: "center" },
        Cell: ({ original }) => {
          if (
            original.account_list_subscriptions.some(
              (sub) => sub.userId === props.user.emails[0].value
            )
          ) {
            return <button onClick={() => unsubscribe(original)}>UnSub</button>;
          } else {
            return <button onClick={() => subscribe(original)}>Sub</button>;
          }
        },
      },
      {
        Header: "Subscribers",
        id: "subscribers",
        accessor: (row) =>
          row.account_list_subscriptions.map((sub) => sub.userId).join(", "),
      },
    ];
    return columns;
  }

  return (
    <ReactTable
      style={{ minHeight: "calc(100vh - 60px - 70px)" }}
      loading={props.loading}
      loadingText={<LoadingIcon />}
      filterable
      defaultFilterMethod={(filter, row) => {
        try {
          return new RegExp(filter.value, "i").test(row[filter.id]);
        } catch (err) {
          return true;
        }
      }}
      data={props.accountLists || []}
      columns={getColumns()}
      defaultSorted={[
        {
          id: "name",
          desc: false,
        },
      ]}
      defaultPageSize={100}
    />
  );
}
