import React, { Component } from "react";
import Clipboard from "./Clipboard";
import moment from "moment-timezone";

class SubTable extends Component {
  borderStyle = { border: "1px solid #b0b0b0" };

  keyProperCase(key) {
    return key.substring(0, 1).toUpperCase() + key.substring(1);
  }

  infoRow(key, value) {
    if (
      typeof value === "string" &&
      value.includes("T") &&
      value.includes("Z") &&
      moment(value).isValid()
    ) {
      value = moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
    if(key === "purchaseId"){
      value = <a href={`https://skybox.vividseats.com/purchases/${value}`} target="_blank">{value}</a>
    }
    if(key === "confirmationNumber"){
      value = <a href={`https://skybox.vividseats.com/reports/12?parameters=%7B%22id%22:12,%22draft%22:false,%22internalNotes%22:%22${value.replace("/", "%2F")}%22%7D`} target="_blank">{value}</a>
    }
    return (
      <tr key={key + value}>
        {this.TD(this.keyProperCase(key))}
        {this.TD(<Clipboard value={value} />)}
        {this.TD(value, true)}
      </tr>
    );
  }

  TD(value, fillSpace) {
    let cellStyle = {
      padding: "5px",
      whiteSpace: "nowrap",
      width: "1%",
      border: "1px solid #b0b0b0"
    };
    if (fillSpace) {
      cellStyle.width = "auto";
    }
    return <td style={cellStyle}>{value}</td>;
  }

  infoTable(info) {
    let tableStyle = { marginLeft: "35px", width: "inherit !important" };
    return (
      <table style={tableStyle}>
        <tbody>
          {Object.keys(info).map(key => {
            return this.infoRow(key, info[key]);
          })}
        </tbody>
      </table>
    );
  }

  quantityRow(key, value) {
    return (
      <tr>
        {this.TD(key)}
        {this.TD(value)}
      </tr>
    );
  }

  quantityTable(quantities) {
    return (
      <table>
        <tbody>
          {this.quantityRow("Last 365 days", quantities.lastYear)}
          {this.quantityRow("This year (since 1 January)", quantities.thisYear)}
          {this.quantityRow("Last 7 days", quantities.lastWeek)}
        </tbody>
      </table>
    );
  }

  render() {
    let smallCell = { width: "25%", verticalAlign: "top" };
    let expendableCell = { width: "auto" };
    const { data, quantities } = this.props;
    return (
      <table>
        <tbody>
          <tr>
            <td style={smallCell}>{this.infoTable(data)}</td>
            {quantities && (
              <td style={smallCell}>{this.quantityTable(quantities)}</td>
            )}
            <td style={expendableCell} />
          </tr>
        </tbody>
      </table>
    );
  }
}
export default SubTable;
