import React, { Component } from "react";
import UserList from "./UserList";
import UserCreator from "./UserCreator";
import UserRoles from "../../../components/UserRoles";

class UserListContainer extends Component {
  constructor() {
    super();
    this.state = { loading: false };
  }

  componentDidMount() {
    this.getUsers();
  }

  async getUsers() {
    let URL = "/api/users/";
    try {
      const response = await fetch(URL);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const users = await response.json();
        this.setState({
          users,
          loading: false
        });
      }
    } catch (err) {
      console.error(err);
    }
    this.setState({ loading: false });
  }

  handleUserChange(users) {
    this.setState({ users });
  }

  handleUserCreated() {
    this.getUsers();
  }

  render() {
    return (
      <div>
        <UserCreator onUserCreated={this.handleUserCreated.bind(this)} />
        {this.state.users && (
          <UserList
            onChangeUser={this.handleUserChange.bind(this)}
            users={this.state.users}
            roles={UserRoles}
          />
        )}
      </div>
    );
  }
}

export default UserListContainer;
