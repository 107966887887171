import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment-timezone";
import LoadingIcon from "../../../components/LoadingIcon";
import CellPromptEditable from "../../../components/PromptEditable";
import CreditCardTable from "./CreditCardTable";

export default function AccountTable(props) {
  const deniedAccontList = JSON.parse(
    process.env.REACT_APP_ACCOUNT_LIST_1TICKET_DISABLED
  );

  async function itemChanged(item) {
    props.onUpdateAccount();
    const result = await axios.post("/api/accounts/", item);
    if (result.data.email) {
      props.onAccountUpdated(result.data);
    }
    props.onAccountUpdated();
  }

  function renderPromptEditable(cellInfo) {
    return (
      <CellPromptEditable
        item={props.accounts[cellInfo.index]}
        cellInfo={cellInfo}
        itemChanged={itemChanged}
      />
    );
  }

  async function removeFromList(email, associationId) {
    props.onRemoveFromList(email, associationId);
    axios.post("/api/accountLists/deassociate", {
      id: associationId,
    });
  }

  const syncPasswordWithOneTicket = async (infos) => {
    props.onUpdateOneTicket(infos.email);
    const result = await axios.post("/api/oneticket", {
      email: infos.email,
      password: infos.password,
    });
    props.onOneTicketUpdated(infos.email, result.data);
  };

  function displayList(object) {
    return (
      object.account_list_accounts !== undefined &&
      object.account_list_accounts
        .sort((a, b) => {
          var nameA = a.account_lists[0].name.toUpperCase();
          var nameB = b.account_lists[0].name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map((list) => (
          <div key={`${object.email}-${list.id}`}>
            {list.id && (
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => removeFromList(object.email, list.id)}
                role="img"
              >
                ❌
              </span>
            )}
            <span>{list.account_lists[0].name}</span>
          </div>
        ))
    );
  }

  const canSyncTo1Ticket = (account) => {
    return !account.account_list_accounts.some((ala) =>
      deniedAccontList.includes(ala.accountListId)
    );
  };

  const columns = [
    {
      Header: (
        <div>
          <span>Add to</span>
          <br />
          <span>{props.currentList.name}</span>
        </div>
      ),
      id: "Email",
      filterable: false,
      width: 100,
      Cell: ({ original }) =>
        props.currentList.id ? (
          <button onClick={() => props.onAddToList(original)}>
            Add to {props.currentList.name}
          </button>
        ) : (
          <div />
        ),
    },
    {
      id: "lists",
      Header: "List(s)",
      width: 100,
      accessor: (r) => r.account_list_accounts,
      Cell: ({ original }) => displayList(original),
      filterMethod: (filter, row) => {
        if (filter.value === "") {
          return true;
        }
        try {
          const re = new RegExp(filter.value, "i");
          return (
            filter.value == null ||
            !filter.value ||
            (row._original &&
              row._original.account_list_accounts.some((association) => {
                return re.test(association.account_lists[0].name);
              }))
          );
        } catch (err) {
          return true;
        }
      },
    },
    {
      Header: (
        <div>
          <span>Total Tickets</span>
          <br />
          <span>(365 Days)</span>
        </div>
      ),
      id: "lastYear",
      accessor: "lastYear",
      show: false,
      width: 100,
    },
    {
      Header: (
        <div>
          <span>Total Tickets</span>
          <br />
          <span>(since 1 Jan)</span>
        </div>
      ),
      id: "thisYear",
      accessor: "thisYear",
      show: false,
      width: 100,
    },
    {
      Header: (
        <div>
          <span>Total Tickets</span>
          <br />
          <span>(7 Days)</span>
        </div>
      ),
      id: "lastWeek",
      accessor: "lastWeek",
      show: false,
      width: 100,
    },
    {
      Header: "Index",
      accessor: "index",
      Cell: renderPromptEditable,
      sortMethod: (a, b) => {
        if (a == null) {
          return 1;
        }
        if (b == null) {
          return -1;
        }
        return a > b ? 1 : -1;
      },
      width: 50,
    },
    { Header: "Email", accessor: "email", width: 185 },
    { Header: "Password", accessor: "password", Cell: renderPromptEditable },
    {
      Header: "1Ticket",
      accessor: "oneTicketSynced",
      Cell: (cellInfo) => (
        <div style={{ textAlign: "center" }}>
          {props.updatingOneTicket === cellInfo.original.email ? (
            <LoadingIcon />
          ) : cellInfo.original.oneTicketSynced ? (
            <img
              src="/img/greenCheckmark.png"
              alt="synced"
              style={{ width: "25px", height: "25px" }}
            />
          ) : canSyncTo1Ticket(cellInfo.original) ? (
            <button
              className="btn btn-outline-primary btn-xsm"
              onClick={() => syncPasswordWithOneTicket(cellInfo.original)}
            >
              Sync
            </button>
          ) : (
            <span>DS</span>
          )}
        </div>
      ),
      width: 65,
    },
    {
      Header: "1TicketStatus",
      Cell: (cellInfo) => {
        const found = props.oneTicketStatuses.find(
          (o) => o.email.toLowerCase() === cellInfo.original.email.toLowerCase()
        );
        if (!canSyncTo1Ticket(cellInfo.original)) {
          return (
            <div className="longText" style={{ background: "rgb(231 255 34)" }}>
              Should not be in 1 Ticket
            </div>
          );
        } else if (found) {
          return (
            <div className="longText" style={{ background: "#FF8282" }}>
              {found.error}
            </div>
          );
        } else return <div></div>;
      },
    },
    { Header: "Name", accessor: "name", Cell: renderPromptEditable },
    { Header: "Phone", accessor: "phone", Cell: renderPromptEditable },
    { Header: "Proxy", accessor: "proxy", Cell: renderPromptEditable },
    { Header: "Address", accessor: "address", Cell: renderPromptEditable },
    { Header: "City", accessor: "city", Cell: renderPromptEditable },
    { Header: "Metro", accessor: "metro", Cell: renderPromptEditable },
    {
      Header: "Postal Code",
      accessor: "postalCode",
      Cell: renderPromptEditable,
    },
    { Header: "State", accessor: "state", Cell: renderPromptEditable },
    {
      Header: "Fan Verif States",
      accessor: "fanVerifStates",
      Cell: renderPromptEditable,
    },
    { Header: "Country", accessor: "country", Cell: renderPromptEditable },
    { Header: "Comment", accessor: "comment", Cell: renderPromptEditable },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: (cellInfo) => (
        <span className="longText">
          {moment(cellInfo.value).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      ),
    },
  ];

  return (
    <ReactTable
      style={{ minHeight: "calc(100vh - 60px - 70px)" }}
      loading={props.loading || props.updatingAccount}
      loadingText={<LoadingIcon />}
      filterable
      // getTrProps={getTrProps}
      defaultFilterMethod={(filter, row) => {
        try {
          return new RegExp(filter.value, "i").test(row[filter.id]);
        } catch (err) {
          return true;
        }
      }}
      data={props.accounts || []}
      columns={columns}
      SubComponent={(row) => {
        const account = props.accounts[row.row._index];
        return (
          <CreditCardTable
            creditCards={account.credit_cards}
            onRemove={(ccId) => props.onRemoveCreditCard(account, ccId)}
            onCreate={() => props.onCreateCreditCard(account)}
            onChange={(cc) => props.onChangeCreditCard(account, cc)}
            onChangeCardType={(target, cc) =>
              props.onChangeCreditCardType(account, target, cc)
            }
          />
        );
      }}
      collapseOnDataChange={false}
      defaultSorted={[
        {
          id: "index",
          desc: false,
        },
      ]}
      defaultPageSize={100}
    />
  );
}
