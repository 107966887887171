import React, { Component } from "react";
import ReactTable from "react-table";
import SubTable from "../../../components/SubTable";
import LoadingIcon from "../../../components/LoadingIcon";
import moment from "moment-timezone";

class FlipTable extends Component {
  formatPrice(price) {
    return `$${parseFloat(price).toFixed(2)}`;
  }

  getColumns() {
    return [
      {
        Header: "Purchase Date",
        id: "purchaseDateTime",
        accessor: (row) =>
          moment.tz(row.purchaseDateTime, "UTC").format("YYYY-MM-DD HH:mm:ss"),
        width: 132,
      },
      {
        Header: "Confirmation #",
        id: "confirmationNumber",
        accessor: "confirmationNumber",
        width: 132,
      },
      {
        Header: "Site",
        id: "site",
        accessor: "site",
        width: 132,
      },
      {
        Header: "Fee",
        id: "fee",
        accessor: (row) => this.formatPrice(row.fee),
        getProps: (state, rowInfo) => {
          return { style: { textAlign: "right" } };
        },
        width: 132,
      },
      {
        Header: "Unit Cost",
        id: "unitCost",
        accessor: (row) => this.formatPrice(row.unitCost),
        getProps: (state, rowInfo) => {
          return { style: { textAlign: "right" } };
        },
        width: 132,
      },
      {
        Header: "Total Cost",
        id: "totalCost",
        accessor: (row) => this.formatPrice(row.totalCost),
        getProps: (state, rowInfo) => {
          return { style: { textAlign: "right" } };
        },
        width: 132,
      },
      {
        Header: "Total Cost With Fee",
        id: "totalCostWithFee",
        accessor: (row) => this.formatPrice(row.totalCostWithFee),
        getProps: (state, rowInfo) => {
          return { style: { textAlign: "right" } };
        },
        width: 132,
      },
      {
        Header: "Is POed",
        id: "hasSkyboxPurchase",
        accessor: (row) => (row.hasSkyboxPurchase || false).toString(),
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.original) {
            return {
              style: {
                background: !rowInfo.original.hasSkyboxPurchase
                  ? "red"
                  : "green",
              },
            };
          } else {
            return {};
          }
        },
        width: 72,
      },
      {
        Header: "Event Date",
        id: "eventDateTime",
        accessor: (row) =>
          moment.tz(row.eventDateTime, "UTC").format("YYYY-MM-DD HH:mm"),
        width: 132,
      },
      {
        Header: "Event Name",
        id: "event",
        accessor: "event",
      },
    ];
  }

  render() {
    return (
      <ReactTable
        style={{ minHeight: "calc(100vh - 60px - 114px - 46px)" }}
        filterable
        loading={this.props.loading}
        loadingText={<LoadingIcon />}
        defaultFilterMethod={(filter, row) => {
          let filterValue = filter.value;
          let endWithSlash;
          do {
            endWithSlash = filterValue[filterValue.length - 1] === "\\";
            if (endWithSlash) {
              filterValue = filterValue.slice(0, -1);
            }
          } while (endWithSlash);

          const re = new RegExp(filterValue, "i");
          return re.test(String(row[filter.id]));
        }}
        data={this.props.data || []}
        SubComponent={(row) => {
          if (row.original.hasSkyboxPurchase) {
            return <SubTable data={row.original.skyboxPurchase} />;
          }
          return null;
        }}
        columns={this.getColumns()}
        defaultSorted={[
          {
            id: "purchaseDateTime",
            desc: false,
          },
        ]}
        defaultPageSize={100}
      />
    );
  }
}

export default FlipTable;
