import React from "react";

export default (props) => {
  return (
    <div style={{ ...props.style }}>
      <h3>Import</h3>
      <div>
        {props.canImportAccount ? (
          <button className="btn" onClick={props.onImportAccount}>
            Import Account
          </button>
        ) : (
          <span>Please select Email field in column Account Fields</span>
        )}
      </div>
      <div>
        {props.canImportCC ? (
          <button className="btn" onClick={props.onImportCreditCard}>
            Import CreditCard
          </button>
        ) : (
          <span>Please select Email field in column Credit Card Fields</span>
        )}
      </div>
    </div>
  );
};
