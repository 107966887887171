export const validateByType = (data, column, model) => {
  if (model.type === "") {
    return "";
  }
  try {
    let error = "";
    if (model.type === "int") {
      error = validateColumnInt(data, column, model);
    } else if (model.type === "email") {
      error = validateColumnEmail(data, column, model);
    } else if (model.type === "phone") {
      error = validateColumnPhone(data, column, model);
    } else if (model.type === "cardType") {
      error = validateColumnCardType(data, column, model);
    }
    if (!!error) {
      return error;
    } else {
      return validateColumnMaxLength(data, column, model);
    }
  } catch (error) {
    console.log("error", error);
    return `${model.text} Has an unplanned error : ${error.message}`;
  }
};

const validateColumn = (data, column, model, validateCell) => {
  for (let indexRow = 1; indexRow < data.length; indexRow++) {
    const cell = data[indexRow][column.indexColumn];
    const error = validateCell(cell, model);
    if (!!error) {
      return error;
    }
  }
  return "";
};

const validateColumnInt = (data, cell, model) => {
  return validateColumn(data, cell, model, validateCellInt);
};

const validateColumnEmail = (data, cell, model) => {
  return validateColumn(data, cell, model, validateCellEmail);
};

const validateColumnMaxLength = (data, cell, model) => {
  return validateColumn(data, cell, model, validateCellMaxLength);
};

const validateColumnPhone = (data, cell, model) => {
  return validateColumn(data, cell, model, validateCellPhone);
};

const validateColumnCardType = (data, cell, model) => {
  return validateColumn(data, cell, model, validateCellCardType);
};

const validateCellInt = (cell, model) => {
  const parsed = parseInt(cell, 10);
  if (parsed != cell) {
    return `${model.text} has a non numeric only. Value: "${cell}".`;
  }
  return "";
};

const validateCellEmail = (cell, model) => {
  if (!cell.match(/.+@.+\..+/i)) {
    return `${model.text} must be an email format ex: asdf@asdf.com. Value: "${cell}".`;
  }
  return "";
};

const validateCellMaxLength = (cell, model) => {
  if (cell.length > model.maxLength) {
    console.log("row[columnFound.indexColumn]", cell);
    return `${model.text} has a too long value, must be under ${model.maxLength} characters. Value: "${cell}".`;
  }
  return "";
};

const validateCellPhone = (cell, model) => {
  if (!cell.match(/\D+/i)) {
    return `${model.text} must be only numerical values. Value: "${cell}".`;
  }
  return "";
};

const validateCellCardType = (cell, model) => {
  if (!cell.match(/[amex|hsbc|comdata|mc]/i)) {
    return `${model.text} must be one of these: amex, hsbc, comdata, mc. Value: "${cell}".`;
  }
  return "";
};
