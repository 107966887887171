import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";

export default class Topbar extends Component {
  render() {
    let { user } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <TopbarSidebarButton />
          <Link className="topbar__logo" to="/pages/account" />
          <div className="topbar__right">
            <TopbarProfile user={user} />
          </div>
        </div>
      </div>
    );
  }
}
