import React, { useState } from "react";
import axios from "axios";
import InputEnterAction from "../InputEnterAction";

export default function CreateAccountList(props) {
  const [name, setName] = useState(() => {});

  async function handleCreateList() {
    if (name && name.length > 0) {
      await axios.post("/api/accountLists", { name });
      setName("");
      if (props.onAccountListCreated) {
        props.onAccountListCreated();
      }
    }
  }

  return (
    <fieldset>
      <legend>Create a List</legend>
      <div className="search-field">
        <InputEnterAction
          value={name}
          placeholder="New List Name"
          onChange={(v) => setName(v.target.value)}
          onEnter={handleCreateList}
        />
      </div>
    </fieldset>
  );
}
