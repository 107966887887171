import React, { Component } from "react";
import { Input, Button } from "reactstrap";
import axios from "axios";
import AsyncForeach from "../../AsyncForeach";
import LoadingIcon from "../../components/LoadingIcon";

class Distributick extends Component {
  constructor() {
    super();
    this.state = {
      data: "",
      loading: false,
      platform: "",
      missing: [],
      infoMissing: [],
      indexChanged: []
    };
  }

  async onImport() {
    const { data, platform } = this.state;
    this.setState({
      loading: true,
      missing: [],
      infoMissing: [],
      indexChanged: []
    });
    const allAccounts = await this.getAllAccounts();
    const regex = /(\d{1,3}).*?,([^,]+@[^,]+),.*/i;
    await AsyncForeach(data.split("\n"), async row => {
      const tested = row.match(regex);
      const index = parseInt(tested[1]);
      const email = tested[2];
      let foundAccount = this.findAccount(allAccounts, email);
      if (!foundAccount) {
        const missing = this.state.missing;
        missing.push({ index, email });
        this.setState({ missing });
        foundAccount = { email };
        await this.modifyAccount(foundAccount, index, platform);
      } else if (
        !foundAccount.index ||
        parseInt(foundAccount.index, 10) !== index ||
        !foundAccount.platform ||
        foundAccount.platform !== platform
      ) {
        const indexChanged = this.state.indexChanged;
        indexChanged.push({ index, email, platform });
        this.setState({ indexChanged });
        await this.modifyAccount(foundAccount, index, platform);
      }
      if (this.isAccountMissingInfo(foundAccount)) {
        const infoMissing = this.state.infoMissing;
        infoMissing.push({ index, email });
        this.setState({ infoMissing });
      }
    });
    this.setState({ loading: false });
  }

  isAccountMissingInfo(account) {
    return !account || !account.name || !account.password;
  }

  findAccount(allAccounts, email) {
    return allAccounts.find(a => a.email.toLowerCase() === email.toLowerCase());
  }

  async modifyAccount(foundAccount, index, platform) {
    foundAccount.index = index;
    foundAccount.platform = platform;
    await axios.post("/api/accounts/", foundAccount);
  }

  async getAllAccounts() {
    return await this.callApi("/api/accounts/");
  }

  async callApi(url) {
    this.setState({ loading: true });
    try {
      const response = await fetch(url);
      this.setState({ loading: false });
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const data = await response.json();
        return data;
      }
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
    return [];
  }

  dataChange(data) {
    this.setState({ data: data.target.value });
  }

  platformChange(platform) {
    this.setState({ platform: platform.target.value });
  }

  render() {
    const { loading, missing, infoMissing, indexChanged } = this.state;
    const styleColumn = {
      display: "inline-block",
      padding: "10px",
      verticalAlign: "top"
    };
    return (
      <div>
        <div>
          Accounts :
          <Input
            type="textarea"
            name="text"
            id="dataToImport"
            style={{ paddingTop: "5px" }}
            onChange={this.dataChange.bind(this)}
          />
        </div>
        <div style={{ paddingTop: "10px" }}>
          Platform :
          <Input
            type="text"
            name="platform"
            id="platform"
            style={{
              display: "inline-block",
              paddingRight: "10px",
              width: "200px"
            }}
            onChange={this.platformChange.bind(this)}
          />
          <Button
            color="primary"
            onClick={this.onImport.bind(this)}
            style={{
              verticalAlign: "middle",
              marginBottom: "0px",
              marginLeft: "5px",
              marginRight: "5px"
            }}
          >
            Import
          </Button>
        </div>
        {loading && <LoadingIcon />}
        <div style={{ verticalAlign: "top" }}>
          <div style={styleColumn}>
            <h3>Accounts with index/platform changed :</h3>
            {indexChanged.map(a => {
              return (
                <div>
                  <span>
                    {a.index} {a.email}
                  </span>
                </div>
              );
            })}
          </div>
          <div style={styleColumn}>
            <h3>Missing accounts :</h3>
            {missing.map(a => {
              return (
                <div>
                  <span>
                    {a.index} {a.email}
                  </span>
                </div>
              );
            })}
          </div>
          <div style={styleColumn}>
            <h3>Accounts with missing info :</h3>
            {infoMissing.map(a => {
              return (
                <div>
                  <span>
                    {a.index} {a.email}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Distributick;