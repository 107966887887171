import React, { Component } from "react";

class Clipboard extends Component {
  copyToClipboard(element) {
    var textField = document.createElement("textarea");
    textField.innerText = element.currentTarget.attributes["value"].value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }

  render() {
    let style = { cursor: "pointer" };
    return (
      this.props &&
      this.props.value && (
        <div
          onClick={this.copyToClipboard}
          value={this.props.value}
          style={style}
        >
          <span role="img" aria-label="clipboard">
            📋
          </span>
        </div>
      )
    );
  }
}

export default Clipboard;
