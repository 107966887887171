import React, { Component } from "react";
import UserListContainer from "./components/UserListContainer";

class Users extends Component {
  render() {
    return <UserListContainer />;
  }
}

export default Users;
