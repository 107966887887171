import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import InputEnterAction from "../../../components/InputEnterAction";
import Autocomplete from "../../../components/Autocomplete";

class SearchBar extends Component {
  constructor() {
    super();
    let eventName = JSON.parse(localStorage.getItem("eventName") || "{}");
    let venue = localStorage.getItem("venue") || "";
    let eventDate = localStorage.getItem("eventDate") || undefined;

    this.state = {
      performers: [],
      eventName,
      venue,
      eventDate,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getPerformers();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getPerformers() {
    const URL = "/api/performers";
    try {
      const response = await fetch(URL);
      if (response.status === 401) {
        window.location.reload(true);
      } else if (response.status === 200) {
        const performers = await response.json();
        if (this.mounted) {
          this.setState({
            performers: performers,
            loading: false,
          });
        }
        return true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  async handleChangeEventName(eventName) {
    await this.setState({ eventName });
    localStorage.setItem("eventName", JSON.stringify(eventName));
  }

  handleChangeVenue(event) {
    this.setState({ venue: event.target.value });
    localStorage.setItem("venue", event.target.value);
  }

  handleChangeDate(event) {
    if (event != null) {
      let date = moment(event).format("YYYY-MM-DD 13:00:00");
      this.setState({ eventDate: date });
      localStorage.setItem("eventDate", date);
    } else {
      this.setState({ eventDate: undefined });
      localStorage.removeItem("eventDate");
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  handleSearch() {
    this.props.onSearch(
      this.state.eventName.name,
      this.state.venue,
      this.state.eventDate
    );
  }

  render() {
    const {
      handleSearch,
      handleChangeVenue,
      handleChangeDate,
      handleChangeEventName,
      handleKeyPress,
      state: { venue, performers, eventDate, eventName },
    } = this;
    return (
      <div>
        <fieldset>
          <legend>Event:</legend>
          <div className="search-field">
            <Autocomplete
              onChange={handleChangeEventName.bind(this)}
              onEnter={handleSearch.bind(this)}
              suggestions={performers}
              value={eventName.name}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Venue:</legend>
          <div className="search-field">
            <InputEnterAction
              value={venue}
              onChange={handleChangeVenue.bind(this)}
              onEnter={handleSearch.bind(this)}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Event Date:</legend>
          <div className="search-field">
            <DatePicker
              className={"input"}
              id="date-field"
              autocomplete="off"
              selected={eventDate ? new Date(eventDate) : null}
              onChange={handleChangeDate.bind(this)}
              onKeyPress={handleKeyPress.bind(this)}
            />
          </div>
        </fieldset>
        <div className="search-field">
          <button
            className={"btn btn-outline-primary"}
            onClick={handleSearch.bind(this)}
          >
            Search
          </button>
        </div>
      </div>
    );
  }
}

export default SearchBar;
