import React from "react";

export default function (props) {
  async function removeFromList(associationId) {
    props.onRemoveFromList(associationId);
  }

  return (
    props.lists !== undefined &&
    props.lists
      .sort((a, b) => {
        var nameA = a.account_lists[0].name.toUpperCase();
        var nameB = b.account_lists[0].name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((list) => (
        <div
          key={list.id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {list.id && (
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "large" }}
              onClick={() => removeFromList(list.id)}
              role="img"
            >
              ❌
            </span>
          )}
          <span>{list.account_lists[0].name}</span>
        </div>
      ))
  );
}
