import React from "react";
import moment from "moment-timezone";
import Excel from "../../../components/Table/Excel";
import { isMatch } from "../logic/transactionMatcher";

function ExportButton(props) {
  function formatSkyboxData() {
    if (props.skyboxData) {
      const skyboxData = props.skyboxData.map((r) => {
        const purchaseDate = moment
          .tz(r.purchaseDateTime, "UTC")
          .format("YYYY-MM-DD");
        const purchaseTime = moment
          .tz(r.purchaseDateTime, "UTC")
          .format("HH:mm:ss");
        const eventDate = moment
          .tz(r.eventDateTime, "UTC")
          .format("YYYY-MM-DD HH:mm");
        const eventTime = moment.tz(r.eventDateTime, "UTC").format("HH:mm");
        const transactionId = r.transaction ? r.transaction.id : "";
        const transactionPurchaseDate = r.transaction
          ? r.transaction.purchaseDate
          : "";
        const transactionPurchaseTime = r.transaction
          ? r.transaction.purchaseTime
          : "";
        return {
          purchaseDate,
          purchaseTime,
          eventDate,
          eventTime,
          transactionId,
          transactionPurchaseDate,
          transactionPurchaseTime,
          ...r,
        };
      });
      return skyboxData;
    }
    return [];
  }
  function formatFlipData() {
    if (props.flipData) {
      const flipData = props.flipData.map((r) => {
        const purchaseDate = moment
          .tz(r.purchaseDateTime, "UTC")
          .format("YYYY-MM-DD");
        const purchaseTime = moment
          .tz(r.purchaseDateTime, "UTC")
          .format("HH:mm:ss");
        const eventDate = moment
          .tz(r.eventDateTime, "UTC")
          .format("YYYY-MM-DD HH:mm");
        const eventTime = moment.tz(r.eventDateTime, "UTC").format("HH:mm");
        const skyboxPurchaseDate = r.skyboxPurchase
          ? r.skyboxPurchase.purchaseDate
          : "";
        const skyboxPurchaseTime = r.skyboxPurchase
          ? r.skyboxPurchase.purchaseTime
          : "";

        return {
          purchaseDate,
          purchaseTime,
          eventDate,
          eventTime,
          skyboxPurchaseDate,
          skyboxPurchaseTime,
          ...r,
        };
      });
      return flipData;
    }
    return [];
  }
  const formatData = (data) => {
    if (data) {
      return data.map((a) => {
        const {
          purchaseDate,
          creditCard,
          site,
          description,
          confirmationNumber,
          skyboxPurchase,
        } = a;
        const realConfirmationNumber = skyboxPurchase
          ? skyboxPurchase.confirmationNumber
          : "";
        const skyboxCost = skyboxPurchase
          ? parseFloat(skyboxPurchase.cost)
          : undefined;
        const bmoCost = parseFloat(a.totalCost);
        const last4 =
          a.creditCard && a.creditCard.length >= 4
            ? a.creditCard.substr(a.creditCard.length - 4)
            : "";
        return {
          matched: isMatch(props.dsAccounts, a),
          purchaseDate,
          creditCard,
          last4,
          supplier: site,
          description,
          confirmationNumber,
          realConfirmationNumber,
          cost: bmoCost,
          diff: skyboxCost ? skyboxCost - bmoCost : "",
        };
      });
    }
    return [];
  };
  const bmoData = formatData(props.bmoData);
  const comdataData = formatData(props.comdataData);
  const amexData = formatData(props.amexData);
  const skyboxData = formatSkyboxData();
  const flipData = formatFlipData();
  return (
    <Excel
      filename={`Accounting_${moment().format("YYYY-MM-DD")}.xlsx`}
      onDownload={(callback) => {
        callback();
      }}
      element={
        <button
          className={"btn btn-outline-primary btn-large"}
          style={{ marginBottom: "0px" }}
        >
          <i className="glyphicon glyphicon-save" />
          Export
        </button>
      }
      style={{ marginBotom: "0px" }}
    >
      <Excel.Sheet data={amexData} name="Amex">
        <Excel.Column label="Matched" value="matched" />
        <Excel.Column label="Charge Date" value="purchaseDate" />
        <Excel.Column label="Credit Card" value="creditCard" />
        <Excel.Column label="Last 4" value="last4" />
        <Excel.Column label="Supplier" value="supplier" />
        <Excel.Column label="Description" value="description" />
        <Excel.Column label="Confirmation Number" value="confirmationNumber" />
        <Excel.Column
          label="Real Confirmation Number"
          value="realConfirmationNumber"
        />
        <Excel.Column label="Cost" value="cost" />
        <Excel.Column label="diff" value="diff" />
      </Excel.Sheet>
      <Excel.Sheet data={comdataData} name="Comdata">
        <Excel.Column label="Matched" value="matched" />
        <Excel.Column label="Charge Date" value="purchaseDate" />
        <Excel.Column label="Credit Card" value="creditCard" />
        <Excel.Column label="Last 4" value="last4" />
        <Excel.Column label="Supplier" value="supplier" />
        <Excel.Column label="Description" value="description" />
        <Excel.Column
          label="Real Confirmation Number"
          value="realConfirmationNumber"
        />
        <Excel.Column label="Cost" value="cost" />
        <Excel.Column label="diff" value="diff" />
      </Excel.Sheet>
      <Excel.Sheet data={bmoData} name="BMO">
        <Excel.Column label="Matched" value="matched" />
        <Excel.Column label="Charge Date" value="purchaseDate" />
        <Excel.Column label="Credit Card" value="creditCard" />
        <Excel.Column label="Supplier" value="supplier" />
        <Excel.Column
          label="Real Confirmation Number"
          value="realConfirmationNumber"
        />
        <Excel.Column label="Cost" value="cost" />
        <Excel.Column label="diff" value="diff" />
      </Excel.Sheet>
      <Excel.Sheet data={skyboxData} name="Skybox Inventory">
        <Excel.Column label="Purchase Date" value="purchaseDate" />
        <Excel.Column label="Purchase Time" value="purchaseTime" />
        <Excel.Column label="Confirmation Number" value="confirmationNumber" />
        <Excel.Column label="Event Date" value="eventDate" />
        <Excel.Column label="Event Time" value="eventTime" />
        <Excel.Column label="Event Name" value="event" />
        <Excel.Column label="Bought by" value="creditCard" />
        <Excel.Column label="Cost" value="cost" />
        <Excel.Column label="Linked" value="hasTransaction" />
        <Excel.Column label="CC Id" value="transactionId" />
        <Excel.Column
          label="CC Purchase Date"
          value="transactionPurchaseDate"
        />
        <Excel.Column
          label="CC Purchase Time"
          value="transactionPurchaseTime"
        />
      </Excel.Sheet>
      <Excel.Sheet data={flipData} name="Flips">
        <Excel.Column label="Purchase Date" value="purchaseDate" />
        <Excel.Column label="Purchase Time" value="purchaseTime" />
        <Excel.Column label="Confirmation Number" value="confirmationNumber" />
        <Excel.Column label="Event Date" value="eventDate" />
        <Excel.Column label="Event Time" value="eventTime" />
        <Excel.Column label="Event Name" value="event" />
        <Excel.Column label="Bought by" value="site" />
        <Excel.Column label="Cost" value="cost" />
        <Excel.Column label="Total Cost With Fee" value="totalCostWithFee" />
        <Excel.Column label="Total Cost" value="totalCost" />
        <Excel.Column label="Unit Cost" value="unitCost" />
        <Excel.Column label="Quantity" value="quantity" />
        <Excel.Column label="Fee" value="fee" />
        <Excel.Column label="Linked" value="hasSkyboxPurchase" />
        <Excel.Column label="Skybox Purchase Date" value="skyboxPurchaseDate" />
        <Excel.Column label="Skybox Purchase Time" value="skyboxPurchaseTime" />
      </Excel.Sheet>
    </Excel>
  );
}

export default ExportButton;
