import React, { useState, useEffect } from "react";
import axios from "axios";
import FanCodesTable from "./FanCodesTable";

export default function FanCodes(props) {
  const [fanCodes, setFanCodes] = useState([]);

  async function refreshCodes() {
    let URL = "/api/fanCodes";
    const response = await axios.get(URL);
    const flat = flattenFanCodes(response.data);
    setFanCodes(flat);
  }

  function flattenFanCodes(codes) {
    return codes.reduce((codeAcc, currentCode) => {
      let { account, ...simpleCode } = currentCode;
      if (account) {
        const { credit_cards, ...simpleAccount } = account;
        simpleCode = { ...simpleAccount, ...simpleCode };
        if (credit_cards) {
          const amex = credit_cards.find(c => c.cardType === 1);
          const comData = credit_cards.find(c => c.cardType === 2);
          const mc = credit_cards.find(c => c.cardType === 3);
          simpleCode = { amex, comData, mc, ...simpleCode };
        }
      }
      codeAcc.push(simpleCode);
      return codeAcc;
    }, []);
  }

  async function handleUpdateFanCode(fanCode) {
    await axios.post("/api/fanCodes/", fanCode);
    setFanCodes([...fanCodes]);
  }

  async function handleDeleteFanCode(fanCode) {
    await axios.delete(`/api/fanCodes/${fanCode.id}`);
    setFanCodes([...fanCodes.filter(c => c.id !== fanCode.id)]);
  }

  async function handleProcessedChange(fanCode) {
    fanCode.processed = !fanCode.processed;
    await handleUpdateFanCode(fanCode);
  }

  useEffect(() => {
    refreshCodes();
  }, []);

  return (
    <div>
      <FanCodesTable
        fanCodes={fanCodes}
        onUpdateFanCode={handleUpdateFanCode}
        onDeleteCode={handleDeleteFanCode}
        onChangeProcessed={handleProcessedChange}
      />
    </div>
  );
}
